import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import DeleteIcon from 'mdi-react/DeleteIcon';
import moment from 'moment';
import renderField from '../../shared/components/custom/Field';
import t, { partial } from '../../util/translation/translation';
// import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderSelectField from '../../shared/components/form/Select';
import FormBox from '../../shared/components/custom/FormBox';
import validations from './validation';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import Api from '../../util/api';
import MatchCenterHelpers from './helpers';
import renderDropZoneField from '../../shared/components/form/DropZoneMS';
import { getImageHash } from '../../util/functions';
import useConfig from '../../util/useConfig';
import renderMultiSelectField from '../../shared/components/form/MultiSelect';
import teamImages from './components/teamImages';
import leagueImages from './components/leagueImages';


const PRSponsorType = {
    SINGLE: 'single',
    ALL: 'all',
};

const UpcomigMatchForm = (props) => {
    const { handleSubmit, initialize, match, isCustomMatch, formValues, change, availablePrizes } = props;
    const s = partial('shared');
    const mc = partial('matchCenter');
    const mf = partial('matchCenter.form');
    const { getSponsor, getCompany, getBannerOptions, getMatchCenterOptions, getTicket, getLinks } = MatchCenterHelpers;
    const { data: companies } = useQuery({ queryKey: ['club-companies'], queryFn: Api.companies.getCompaniesForClub });
    const config = useConfig();

    if (companies && companies[0]?.id) {
      companies.unshift({ name: s('empty'), id: null });
    }

    const MATCH_STATUS_OPTIONS = [
      {
        label: 'Not Started',
        value: 'NOT_STARTED',
      },
      {
        label: 'Ongoing',
        value: 'ONGOING',
      },
      {
        label: 'Finished',
        value: 'FINISHED',
      },
    ];

    useEffect(() => {
      if (!match) {
        initialize({
          isCustomMatch: !!isCustomMatch,
          matchStatus: MATCH_STATUS_OPTIONS[0],
          homeTeamScore: 0,
          awayTeamScore: 0,
        });
        return;
      }
      const { name, image, companyLogo } = getSponsor(match);
      const company = getCompany(match, companies);
      initialize({
        isCustomMatch: !!isCustomMatch,
        homeTeam: isCustomMatch ? match.extraData.homeTeam : undefined,
        homeTeamLogo: isCustomMatch && match.extraData.homeTeamLogo ? {
          name: 'match',
          preview: match.extraData.homeTeamLogo,
          croppedImage: match.extraData.homeTeamLogo,
          hash: getImageHash(match.extraData.homeTeamLogo),
        } : undefined,
        homeTeamScore: isCustomMatch ? match.extraData.homeTeamScore : undefined,
        awayTeam: isCustomMatch ? match.extraData.awayTeam : undefined,
        awayTeamLogo: isCustomMatch && match.extraData.awayTeamLogo ? {
          name: 'match',
          preview: match.extraData.awayTeamLogo,
          croppedImage: match.extraData.awayTeamLogo,
          hash: getImageHash(match.extraData.awayTeamLogo),
        } : undefined,
        awayTeamScore: isCustomMatch ? match.extraData.awayTeamScore : undefined,
        matchStartsAt: isCustomMatch ? moment.utc(match.matchStart).local(true).format('YYYY-MM-DDTHH:mm') : undefined,
        matchType: isCustomMatch ? match.extraData.matchType : undefined,
        matchTypeLogo: isCustomMatch && match.extraData.matchTypeLogo ? {
          name: 'match',
          preview: match.extraData.matchTypeLogo,
          croppedImage: match.extraData.matchTypeLogo,
          hash: getImageHash(match.extraData.matchTypeLogo),
        } : undefined,
        matchStatus: isCustomMatch ? MATCH_STATUS_OPTIONS.find(status => status.value === match.matchStatus) : undefined,
        sponsor_name: name,
        sponsor_image: image,
        sponsor_logo: companyLogo,
        company,
        scope: PRSponsorType.SINGLE,
        visibleInBanner: match.settings?.visibleInBanner,
        administrativeTeam: match?.administrativeTeam ? { label: match?.administrativeTeam?.name, value: match?.administrativeTeam?.id } : undefined,
        prizes: availablePrizes.filter((item) => (match.settings?.settings?.prizes || []).includes(item.value)),
        ...getBannerOptions(match),
        ...getMatchCenterOptions(match),
        ...getTicket(match),
        links: getLinks(match),
      });
    }, [match, isCustomMatch, companies, getBannerOptions]);
    const handleSponsorChange = (event) => {
      if (!event) {
        change('sponsor_image', '');
        return;
      }
      const companyInfo = companies.find(company => company.id === event.value);
      const image = companyInfo?.images?.appTicketImage || '';
      const companyLogo = companyInfo?.images?.companyImage || '';
      change('sponsor_image', image);
      change('sponsor_logo', companyLogo);
    };

    const onPressUseTeamLogo = async (field) => {
      console.log(field);
      const teamLogo = document.getElementById('team-logo').src;
      change(field, {
        name: 'match',
        preview: teamLogo,
        croppedImage: teamLogo,
        hash: getImageHash(teamLogo),
      });
    };

    const onChangeUseTeamLogo = async (field, type) => {
      if (!field || !type) {
        return;
      }
      const teamLogo = field.value;
      console.log(type);
      change(`${type}Logo`, {
        name: 'match',
        preview: teamLogo,
        croppedImage: teamLogo,
        hash: getImageHash(teamLogo),
      });
      change(type, field.label);
    };

    const renderLinks = useCallback(({ fields }) => (
      <>
        {fields.map((input, index) => (
          <Row key={index.toString()}>
            <Col md={11}>
              <Row>
                <Col lg={4} md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{s('btnText')}</span>
                    <Field
                      name={`${input}.link_name`}
                      placeholder={s('btnText')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col lg={4} md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{mf('link')}</span>
                    <Field
                      name={`${input}.link_link`}
                      placeholder={mf('link')}
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col lg={4} md={12}>
                  <div className="form__form-group">
                    <span className="form-header">{mf('type')}</span>
                    <Field
                      disabled
                      name={`${input}.link_type`}
                      placeholder={mf('type')}
                      options={[{ label: mf('external'), value: 'external' }, { label: mf('internal'), value: 'internal' }]}
                      component={renderSelectField}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={1}>
              <button type="button" style={{ background: 'transparent', border: 'none', marginTop: '18px' }} onClick={() => fields.remove(index)}>
                <DeleteIcon size={16} />
              </button>
            </Col>
          </Row>
        ))}
        <button type="button" style={{ background: 'transparent', border: 'none', color: '#70bbfd' }} onClick={() => fields.push({})}>
          {mf('addNewLink')}
        </button>
      </>
    ), []);
    return (
      <Card>
        <h5 className="bold mb-2 ml-3">{match ? `${match?.homeTeam?.name ?? match?.extraData?.homeTeam} - ${match?.awayTeam?.name ?? match?.extraData?.awayTeam}` : mf('createMatch')}</h5>
        <form onSubmit={handleSubmit} className="form">
          <Container>
            { isCustomMatch && (
              <>
                <FormBox style={{ marginBottom: 10 }} title={s('match')}>
                  <Col>
                    <Row>
                      <Col lg={6} md={12}>
                        <div className="form__form-group">
                          <Row>
                            <Col>
                              <span className="form-header">{mc('findTeam')}</span>
                              <Field
                                name="homeTeamLogoSelect"
                                placeholder={s('team')}
                                options={teamImages}
                                component={renderSelectField}
                                onChange={(e) => (onChangeUseTeamLogo(e, 'homeTeam'))}
                              />
                            </Col>
                            <Col>
                              <span className="form-header">{mc('homeTeam')}</span>
                              <Field
                                name="homeTeam"
                                component={renderField}
                                placeholder={s('name')}
                              />
                            </Col>
                          </Row>
                          <Button className="mx-0 mb-1 mt-0" onClick={() => onPressUseTeamLogo('homeTeamLogo')} color="primary" size="sm">
                            {mf('useTeamLogoPart1')}
                            {' '}{config.club_name_short}{' '}
                            {mf('useTeamLogoPart2')}
                          </Button>
                          <Field
                            name="homeTeamLogo"
                            maxSize={1}
                            component={renderDropZoneField}
                            crop="MATCHBANNER_IMAGE_CROP"
                            saveBase64Cropped
                            displayCrop
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={12}>
                        <div className="form__form-group">
                          <Row>
                            <Col>
                              <span className="form-header">{mc('findTeam')}</span>
                              <Field
                                name="awayTeamLogoSelect"
                                placeholder={s('team')}
                                options={teamImages}
                                component={renderSelectField}
                                onChange={(e) => (onChangeUseTeamLogo(e, 'awayTeam'))}
                              />
                            </Col>
                            <Col>
                              <span className="form-header">{mc('awayTeam')}</span>
                              <Field
                                name="awayTeam"
                                component={renderField}
                                placeholder={s('name')}
                              />
                            </Col>
                          </Row>
                          <Button className="mx-0 mb-1 mt-0" onClick={() => onPressUseTeamLogo('awayTeamLogo')} color="primary" size="sm">
                            {mf('useTeamLogoPart1')}
                            {' '}{config.club_name_short}{' '}
                            {mf('useTeamLogoPart2')}
                          </Button>
                          <Field
                            name="awayTeamLogo"
                            maxSize={1}
                            component={renderDropZoneField}
                            crop="MATCHBANNER_IMAGE_CROP"
                            saveBase64Cropped
                            displayCrop
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form-header">{mc('findLeague')}</span>
                          <Field
                            name="matchTypeLogoSelect"
                            placeholder={mc('league')}
                            options={leagueImages}
                            component={renderSelectField}
                            onChange={(e) => (onChangeUseTeamLogo(e, 'matchType'))}
                          />
                        </div>
                        <div className="form__form-group">
                          <span className="form-header">{mf('matchType')}</span>
                          <Field
                            name="matchType"
                            component={renderField}
                            placeholder={mf('matchType')}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form-header">{mc('league')}</span>
                          <Field
                            name="matchTypeLogo"
                            maxSize={1}
                            component={renderDropZoneField}
                            crop="MATCHBANNER_IMAGE_CROP"
                            saveBase64Cropped
                            displayCrop
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr/>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form-header">{mf('matchStartsAt')}</span>
                          <Field
                            name="matchStartsAt"
                            component={renderField}
                            type="datetime-local"
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="form__form-group">
                          <span className="form-header">{mf('administrativeTeam')}</span>
                          <Field
                            name="administrativeTeam"
                            component={renderSelectField}
                            placeholder={mf('administrativeTeam')}
                            options={config?.teams?.map?.(team => ({ value: team.id, label: team.name })) ?? []}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </FormBox>
                <FormBox style={{ marginBottom: 10 }} title="Score">
                  <Col>
                    <Row>
                      <Col lg={6} md={12}>
                        <div className="form__form-group">
                          <span className="form-header">{mf('matchStatus')}</span>
                          <Field
                            name="matchStatus"
                            placeholder={mf('matchStatus')}
                            options={MATCH_STATUS_OPTIONS}
                            component={renderSelectField}
                          />
                        </div>
                      </Col>
                      <Col sm={3} xs={6}>
                        <div className="form__form-group">
                          <span className="form-header">{mf('homeTeamScore')}</span>
                          <Field name="homeTeamScore" component={renderField} placeholder={mf('homeTeamScore')} />
                        </div>
                      </Col>
                      <Col sm={3} xs={6}>
                        <div className="form__form-group">
                          <span className="form-header">{mf('awayTeamScore')}</span>
                          <Field name="awayTeamScore" component={renderField} placeholder={mf('awayTeamScore')} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </FormBox>
              </>
            )

            }
            <FormBox style={{ marginBottom: 10 }} title={mf('bannerOptions')}>
              <Col>
                <Row className="form__form-group">
                  <Col md={12}>
                    <div className="flex my-2">
                      <span className="form-header mr-2">{mc('bannerVisibility')}</span>
                      <Field
                        name="visibleInBanner"
                        defaultChecked
                        component={renderCheckBoxField}
                        type="checkbox"
                      />
                    </div>
                  </Col>
                  {formValues?.visibleInBanner && !isCustomMatch &&
                    <Row className="my-2 px-3">
                      <Col md={12} lg={12}>
                        <span className="form-header bold mr-2">{s('buttons')}</span>
                      </Col>
                      <Col md={4}>
                        <div className="flex my-2">
                          <span className="form-header mr-2">{mf('gameCenter')}</span>
                          <Field
                            name="gamecenter"
                            component={renderCheckBoxField}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      {/* <Col>
                      <div className="flex">
                        <span className="form-header mr-2">{mf('playPredict')}</span>
                        <Field
                          name="playpredict"
                          component={renderCheckBoxField}
                          type="checkbox"
                        />
                      </div>
                </Col> */}
                      <Col md={4}>
                        <div className="flex my-2">
                          <span className="form-header mr-2">{mf('lineUp')}</span>
                          <Field
                            name="lineup"
                            component={renderCheckBoxField}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="flex my-2">
                          <span className="form-header mr-2">{mf('mvp')}</span>
                          <Field
                            name="mvp"
                            component={renderCheckBoxField}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                </Row>
              </Col>
            </FormBox>
            <FormBox style={{ marginBottom: 10 }} title={s('matchSponsor')}>
              <Col>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">{mf('matchSponsorName')}</span>
                      <Field
                        onChange={handleSponsorChange}
                        name="sponsor_name"
                        placeholder={mf('matchSponsorName')}
                        options={companies?.map(company => ({ label: company.name, value: company.id, disabled: company?.images?.appTicketImage === '' }))}
                        component={renderSelectField}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormBox>
            <FormBox style={{ marginBottom: 10 }} title={mc('fanRating')}>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{s('sponsor')}</span>
                  <Field
                    name="company"
                    placeholder={mf('playerRatingSponsor')}
                    options={companies?.map(company => ({ label: company.name, value: company.id, disabled: company?.images?.appTicketImage === '' }))}
                    component={renderSelectField}
                    disabled={isCustomMatch}
                  />
                </div>
              </Col>
              <Col>
                <Row>
                  <Col lg={12}>
                    <div className="form__form-group">
                      <span className="form-header">{t('prizes.prizes')}</span>
                      <Field
                        name="prizes"
                        options={availablePrizes}
                        component={renderMultiSelectField}
                        placeholder={t('prizes.prizes')}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormBox>
            <FormBox style={{ marginBottom: 10 }} title={mf('ticketInfo')}>
              <Col>
                <Row>
                  <Col lg={4} md={12}>
                    <div className="form__form-group">
                      <span className="form-header">{s('btnText')}</span>
                      <Field
                        name="ticket_name"
                        placeholder={s('btnText')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={12}>
                    <div className="form__form-group">
                      <span className="form-header">{mf('link')}</span>
                      <Field
                        name="ticket_link"
                        placeholder={mf('link')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  {false &&
                  <Col lg={4} md={12}>
                    <div className="form__form-group">
                      <span className="form-header">{mf('externalId')}</span>
                      <Field
                        name="externalId"
                        placeholder={mf('externalId')}
                        component={renderField}
                      />
                    </div>
                  </Col>
                  }
                </Row>
              </Col>
            </FormBox>
            <FormBox style={{ paddingBottom: 10 }} title={mf('addLinks')}>
              <Col>
                <FieldArray name="links" component={renderLinks} />
              </Col>
            </FormBox>
            <Row className="mt-3">
              <Col>
                <Button type="submit" color="primary">{(match) ? s('update') : s('create')}</Button>
                <Link to="/matches/upcoming"> <Button type="submit" color="secondary">{s('close')}</Button></Link>
              </Col>
            </Row>
          </Container>
        </form>
      </Card>
    );
};

const reduxF = reduxForm({
  form: 'upcoming_match_form',
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('upcoming_match_form')(state),
  validate: validations,
});
export default connect(mapStateToProps)(reduxF(UpcomigMatchForm));
