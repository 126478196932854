import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, TabPane, TabContent, Col, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getFormValues, isValid, touch } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import AuctionHouseForm from './components/AuctionHouseForm';
import NavTab from '../../shared/components/custom/NavTab';
import AuctionItemsList from './components/AuctionItemsList';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import AuctionHouseBids from './components/AuctionHouseBids';
import AuctionHouseAccessForm from './components/AuctionHouseAccessForm';
import t, { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import TabHeader from '../../shared/components/scheduledMessages/TabHeader';
import AuctionHouseContactForm from './components/AuctionHouseContactForm';
import OfferTypes from '../offers/types';

const AuctionHouseFormPage = ({
  auctionHouseAccessFormValues,
  auctionHouseFormValues,
  auctionHouseAccessFormValid,
  auctionHouseFormValid,
  dispatch,
}) => {
  const { auctionID: auctionHouseId } = useParams();
  const history = useHistory();
  const config = useConfig();
  const [handleModal, visible, deleteID] = useModal();
  const [groupsLevelsTiers, setGroupsLevelsTiers] = useState({
    tiers: [],
    groups: [],
    levels: [],
  });
  const [tab, setTab] = useState('0');
  const [auctionHouse, setAuctionHouse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [auctionHouseAccess, setAccess] = useState({});
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] = useState(0);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] = useState(0);
  const [startDate, setStartDate] = useState();
  const p = partial('auctionObjectsShared');
  const s = partial('shared');
  const e = partial('EventsForm');
  const o = partial('OffersFormPage');

  const getAuctionHouse = async () => {
    setLoading(true);
    try {
      const resAuctionHouse = await Api.auctions.getAuctionHouse(auctionHouseId);
      setAuctionHouse(resAuctionHouse);
      setLoading(false);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };
  const getScheduleMessagesByServiceId = (async (page = 1, pageSize = 10) => {
    try {
      let messageByEventId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceId(auctionHouseId, page, pageSize);
      } else {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(auctionHouseId, page, pageSize, messageStatus);
      }
      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total || 0);
      }
      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
    } catch (error) {
      toast.error(error);
    }
  });
  const handleContact = async (values) => {
    const { title, message, type, timeToSend, notificationLabel } = values;
    const scheduledDate = timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null;
    const payload = { title, message, type, tiers: [], levels: [], groups: [], notificationLabel: notificationLabel?.value || null,
    };
    try {
      if (timeToSend === 'SCHEDULED' && scheduleMessageID) {
        await Api.auctions.updateScheduledMessage('auctionHouse', auctionHouseId, scheduleMessageID, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.scheduleMessageUpdated')}`);
      } else if (timeToSend === 'INSTANT') {
        await Api.auctions.contactAuction('auctionHouse', auctionHouseId, payload);
      } else {
        await Api.auctions.contactAuction('auctionHouse', auctionHouseId, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.messageScheduled')}`);
      }
      if (timeToSend === 'INSTANT') {
        if (type === OfferTypes.CommunicationTypes.push) {
          toast.success(o(`${'pushNotificationSent'}`));
        } else if (type === OfferTypes.CommunicationTypes.email) {
          toast.success(o(`${'emailSent'}`));
        } else {
          toast.success(o(`${'smsSent'}`));
        }
      }
    } catch (_e) {
      toast.error(s(`${'somethingWentWrong'}`));
    }
    getScheduleMessagesByServiceId();
    setScheduleMessageID(null);
  };
  const getAuctionHouseAccess = async () => {
    setLoadingAccess(true);
    try {
      const [resAccess, resLevels, resGroups, { data: resTiers }] = await Promise.all([
        Api.auctions.getAuctionHouseAccess(auctionHouseId),
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
      ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
      setAccess(resAccess);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };
  const getGroupsTiersLevels = async () => {
    setLoadingAccess(true);
    try {
      const [resLevels, resGroups, { data: resTiers }] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
      ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };

  useEffect(() => {
    if (auctionHouseId) {
      getAuctionHouse();
      getAuctionHouseAccess();
    } else {
      getGroupsTiersLevels();
    }
  }, [auctionHouseId]);


  const onAuctionHouseSubmit = async (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      image: values.headingImage.croppedBase64 || values.headingImage.preview,
      isActive: values.isActive,
      vat: 0,
    };
    setLoading(true);
    let finalAuctionHouseId = null;
    try {
      if (auctionHouseId) {
        await Api.auctions.updateAuctionHouse(auctionHouseId, payload);
      } else {
        const createAuctionHouseResponse = await Api.auctions.createAuctionHouse(payload);
        finalAuctionHouseId = createAuctionHouseResponse.id;
      }
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    }
    setLoading(false);
    // eslint-disable-next-line consistent-return
    return { finalId: auctionHouseId || finalAuctionHouseId, isNew: !!finalAuctionHouseId };
  };

  const deleteAuctionItem = async () => {
    setLoading(true);
    try {
      await Api.auctions.deleteAuctionItem(deleteID);
      toast.success(`${p('auctionObjectRemoved')}`);
    } catch (err) {
      toast.success(err || `${p('auctionObjectRemovedERROR')}`);
    } finally {
      handleModal();
      setLoading(false);
    }
  };
  const pollAuctionHouseAccess = async (_auctionHouseId, callBack, iteration = 0) => {
    if (iteration > 10) {
      toast.error(p('updatingAccessFailed'));
      history.push('/auctions');
      return;
    }
    try {
      await Api.auctions.getAuctionHouseAccess(_auctionHouseId);
      callBack();
    } catch (err) {
      setTimeout(() => pollAuctionHouseAccess(_auctionHouseId, callBack, iteration + 1), 1000);
    }
  };
  const getAccessValues = (searchArray) => {
    if (searchArray.length === 0) {
      return null;
    }
    const foundItem = searchArray.find(item => Array.isArray(item.value) || item.value === null);
    if (foundItem) {
      return foundItem.value;
    }
    return searchArray.map((item) => item.value);
  };
  const onAccessSubmit = async (_auctionHouseId, values) => {
    const payload = {
      tiers: getAccessValues(values?.tier || []),
      groups: getAccessValues(values?.group || []),
      levels: getAccessValues(values?.level || []),
      noAccessView: values?.noAccessView || false,
    };
    setLoadingAccess(true);
    try {
      await Api.auctions.updateAuctionHouseAccess(_auctionHouseId, payload);
      history.push('/auctions');
      toast.success(p('auctionPlaceUpdated'));
    } catch (err) {
      toast.error(p('updatingAccessFailed'));
    }
    setLoadingAccess(false);
  };
  const handleAuctionHouseSubmit = async () => {
    if (!auctionHouseFormValid) {
      dispatch(touch('auction_house_form', 'name', 'headingImage', 'description', 'isActive', 'vat'));
      return;
    }
    if (!auctionHouseAccessFormValid) {
      dispatch(touch('auction_house_access_form', 'level', 'group', 'tier'));
      return;
    }
    const auctionHouseData = await onAuctionHouseSubmit(auctionHouseFormValues);
    if (!auctionHouseData) {
      return;
    }
    if (auctionHouseData.isNew) {
      setLoadingAccess(true);
      pollAuctionHouseAccess(auctionHouseData.finalId, () => onAccessSubmit(auctionHouseData.finalId, auctionHouseAccessFormValues));
    } else {
      await onAccessSubmit(auctionHouseData.finalId, auctionHouseAccessFormValues);
    }
  };

  return (
    <Container>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={p('theAuctionObjectSmall')}
        modalAction={deleteAuctionItem}
      />
      <Loading loading={loading || loadingAccess || loadingItems} />
      <Row>
        <Col>
          <Card>
            <CardBody className="p-0">
              <NavTab
                tabs={[
                  { tab: `${s('details')}` },
                  { tab: `${p('auctionObjects')}`, disabled: !auctionHouseId },
                  { tab: `${p('bidOverview')}`, disabled: !auctionHouseId },
                  {
                    tab: <TabHeader
                      count={numberOfPendingMessagesPerEvent}
                      heading={s('communication')}
                      iconSize="22"
                      limit={9}
                    />,
                    disabled: !auctionHouseId,
                  },
                ]}
                setTab={setTab}
                activeTab={tab}
                size="sm"
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1" className="m-3">
                  <AuctionHouseForm type={auctionHouseId ? 'edit' : 'create'} auctionHouse={auctionHouse} />
                  <AuctionHouseAccessForm
                    access={auctionHouseAccess}
                    groupsLevelsTiers={groupsLevelsTiers}
                  />
                  <Col md={12}>
                    <Button color="primary" onClick={handleAuctionHouseSubmit}>
                      {auctionHouseId ? s('save') : s('create')}
                    </Button>
                    <Button type="button" onClick={() => history.push('/auctions')}>
                      {s('close')}
                    </Button>
                  </Col>
                </TabPane>
                <TabPane tabId="2" className="m-3">
                  <AuctionItemsList auctionHouseId={auctionHouseId} handleModal={handleModal} deleteVisible={visible} setLoading={setLoadingItems} />
                </TabPane>
                <TabPane tabId="3" className="m-3">
                  <AuctionHouseBids auctionHouseId={auctionHouseId} />
                </TabPane>
                <TabPane tabId="4" className="m-3">
                  <AuctionHouseContactForm
                    onSubmit={handleContact}
                    auctionHouse={auctionHouse}
                    scheduleMessageID={scheduleMessageID}
                    setScheduleMessageID={setScheduleMessageID}
                    scheduleMessagesForEvent={scheduleMessagesForEvent}
                    getScheduleMessagesByServiceId={getScheduleMessagesByServiceId}
                    setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                    totalAmountOfScheduledMessages={totalAmountOfScheduledMessages}
                    messageStatus={messageStatus}
                    setMessageStatus={setMessageStatus}
                    tiers={groupsLevelsTiers.tiers}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auctionHouseAccessFormValues: getFormValues('auction_house_access_form')(state),
  auctionHouseFormValues: getFormValues('auction_house_form')(state),
  auctionHouseAccessFormValid: isValid('auction_house_access_form')(state),
  auctionHouseFormValid: isValid('auction_house_form')(state),
});
export default connect(mapStateToProps)(AuctionHouseFormPage);
