import frisk from './frisk/config';
import narvik from './narvik/config';
import rik from './rik/config';
import sil from './sil/config';
import sparta from './sparta/config';
import stjernen from './stjernen/config';
import tfg from './tfg/config';
import tfgesp from './tfgesp/config';
import vif from './vif/config';
import vik from './vik/config';
import lik from './lik/config';
import cardiff from './cardiff/config';
import dif from './dif/config';
import bik from './bik/config';
import comet from './comet/config';
import aranas from './aranas/config';
import score from './score/config';
import brynas from './brynas/config';
import lif from './lif/config';
import sarpsborg from './sarpsborg/config';
import hammarby from './hammarby/config';
import sodertalje from './sodertalje/config';
import linkoping from './linkoping/config';
import eskil from './eskil/config';
import nybro from './nybro/config';
import falun from './falun/config';
import hacken from './hacken/config';
import degerfors from './degerfors/config';
import ettan from './ettan/config';
import pixbo from './pixbo/config';
import rosengard from './rosengard/config';
import gothiacup from './gothiacup/config';
import elfsborg from './elfsborg/config';
import fbk from './fbk/config';
import valerenga from './valerenga/config';
import hbk from './hbk/config';
import fbc from './fbc/config';
import lorenskog from './lorenskog/config';
import backen from './backen/config';
import boras from './boras/config';
import vastervik from './vastervik/config';
import fcstockholm from './fcstockholm/config';
import bjorkloven from './bjorkloven/config';
import ssk from './ssk/config';
import trekronor from './trekronor/config';
import oilers from './oilers/config';
import lundsbk from './lundsbk/config';
import onsalabk from './onsalabk/config';
import pitea from './pitea/config';
import prod from './prod/config';
import timra from './timra/config';
import frolunda from './frolunda/config';
import lillehammer from './lillehammer/config';
import orebrohk from './orebrohk/config';
import hv71 from './hv71/config';
import villalidkoping from './villalidkoping/config';
import oddsbk from './oddsbk/config';

export default {
  frisk,
  narvik,
  rik,
  sil,
  sparta,
  stjernen,
  tfg,
  vif,
  vik,
  lik,
  cardiff,
  dif,
  tfgesp,
  bik,
  comet,
  aranas,
  score,
  brynas,
  lif,
  sarpsborg,
  hammarby,
  sodertalje,
  linkoping,
  eskil,
  nybro,
  falun,
  hacken,
  degerfors,
  ettan,
  pixbo,
  rosengard,
  gothiacup,
  elfsborg,
  fbk,
  valerenga,
  hbk,
  fbc,
  lorenskog,
  backen,
  boras,
  vastervik,
  fcstockholm,
  bjorkloven,
  ssk,
  trekronor,
  oilers,
  lundsbk,
  onsalabk,
  pitea,
  prod,
  frolunda,
  timra,
  lillehammer,
  orebrohk,
  hv71,
  villalidkoping,
  oddsbk,
};
