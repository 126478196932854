import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Table, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';

const GlobalPricesList = (props) => {
  const [priceGroups, setPriceGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const getGlobalPrices = async () => {
    setLoading(true);
    try {
      const resPriceGroups = await Api.settings.getPriceGroups();
      setPriceGroups(resPriceGroups.data);
      setLoading(false);
    } catch (error) {
      toast.error(p('couldNotFetchPriceGroups'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getGlobalPrices();
  }, []);

  const renderGlobalPricesList = () => {
    return priceGroups.map(priceGroup => (
      <tr className="center-list" key={`team-${priceGroup.id}`}>
        <td className="vertical-alignMiddle">{priceGroup.sort_order}</td>
        <td className="vertical-alignMiddle">
          <Link to={`/settings/price_group/edit/${priceGroup.id}`}>{priceGroup.title}</Link>
        </td>
        <td>{priceGroup.percentage}%</td>
        <td>
          {
            priceGroup.buyable ?
              <CheckboxMarkedCircleIcon color="#399351" />
              :
              <CloseCircleIcon color="#FF4861" />
          }
        </td>
        <td>
          {
            priceGroup.control ?
              <CheckboxMarkedCircleIcon color="#399351" />
              :
              <CloseCircleIcon color="#FF4861" />
          }
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <p><DotsHorizontalIcon /></p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link
                to={`/settings/price_group/edit/${priceGroup.id}`}
              >
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem onClick={() => props.handleModal(true, priceGroup.id, 'prisgruppen', 'deletePriceGroup', getGlobalPrices, p('priceGroupRemoveWarning'))} className="danger">{s('delete')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <Col>
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{p('globalPriseGroups')}</p>
            <Link className="btn btn-primary btn-sm" to="/settings/price_group/create">
              {p('createPriceGroup')}
            </Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('order')}</th>
                <th>{s('title')}</th>
                <th>{s('pricePercent')}</th>
                <th>{p('canBePurchased')}</th>
                <th>{p('requiresControl')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { renderGlobalPricesList() }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

GlobalPricesList.propTypes = {
  handleModal: PropTypes.func.isRequired,
};
export default GlobalPricesList;
