import React from 'react';
import { isSpotlightExclusive } from './SpotlightUtils';
import ExclusiveLabel from './ExclusiveLabel';

const SpotlightTextTile = ({ tile, access }) => {
    const isExclusive = isSpotlightExclusive(access);
    console.log(tile, tile?.headingImage?.croppedImage);
    const { title, description } = tile;
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 52, height: 45, backgroundColor: '#0f69b420', borderRadius: 52, marginRight: 5 }}>
          <img src={tile?.headingImage?.croppedImage} alt="" />
        </div>
        <div style={{
              width: 200,
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: '#0f69b420',
              padding: 15,
              borderRadius: 8,
              borderTopLeftRadius: 0,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 16, color: '#0f69b4', textAlign: 'start' }}>{title || ''}</p>
            <p style={{ fontSize: 16, color: '#0f69b4', textAlign: 'start', marginTop: 5 }}>{description || ''}</p>
          </div>
          <ExclusiveLabel visible={isExclusive} format="LANDSCAPE" />
        </div>
      </div>
    );
};

export default SpotlightTextTile;


