/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import {
  Container, Card, CardBody, Table,
  Button, UncontrolledDropdown, DropdownToggle,
  DropdownMenu, DropdownItem, Row, Col, Toast, ToastHeader, ToastBody,
  CardHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIcon from 'mdi-react/DragIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Select from 'react-select';
import { useParams, useHistory } from 'react-router';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Api from '../../util/api';
import skeletonTableLoader from '../../shared/components/custom/SkeletonLoaders';
import Loading from '../../shared/components/custom/Loading';
import NavTab from '../../shared/components/custom/NavTab';
import GroupForm from './components/GroupForm';
import { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import TeamProfileList from '../settings/components/TeamProfileList';

const PlayersListPage = () => {
  const config = useConfig();
  const { teamId } = useParams();
  const history = useHistory();
  const [selectedTeam, setSelectedTeam] = useState(teamId ? config.teams.find(t => t.id === teamId) : config.teams[0]);
  const [searchPlayers, setSearchPlayers] = useState([]);
  const [selectedTransferPlayer, setSelectedTransferPlayer] = useState(undefined);
  const [transferTeams, setTransferTeams] = useState([]);
  const [playerHandleModal, playerVisible, deletePlayerID] = useModal();
  const [groupHandleModal, groupVisible, groupDeleteID] = useModal();
  const [loadingPlayer, setLoadingPlayer] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [positions, setPositions] = useState([]);
  const [teamLoader, setTeamLoader] = useState();
  const [players, setPlayers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tab, setTab] = useState('');
  const [groupState, setGroupState] = useState(false);
  const p = partial('PlayerListPage');
  const s = partial('shared');

  const getGroups = async () => {
    setLoadingGroup(true);
    try {
      const resGroups = await Api.club.getSettingsByKey('playerGroup');
      setGroups(resGroups.data.map((setting) => {
        const [name, ...values] = setting.value.split(':');
        return {
          id: setting.id,
          order: setting.order,
          name,
          values,
        };
      }));
      setLoadingGroup(false);
    } catch (err) {
      toast.error(err || p('fetchingGroupsFailed'));
      setLoadingGroup(false);
    }
  };
  const fetchTeam = async (id) => {
    try {
      setTeamLoader(true);
      const { data: _players } = await Api.players.getPLayersFromNtb(id);
      setPlayers(_players);
      setPositions(_players.map((_p) => _p.position));
      await getGroups();
      setTeamLoader(false);
    } catch (e) {
      setTeamLoader(false);
      toast.error('Unable to fetch players');
    }
  };

  const _setSelectedTeam = (team) => () => {
    history.replace(`/players/${team.id}`);
    setSelectedTeam(team);
    fetchTeam(team.id);
  };

  const deletePlayer = async () => {
    playerHandleModal();
    setLoadingPlayer(true);
    try {
      await Api.players.deletePlayer(deletePlayerID);
      toast.success(p('playerDeleted'));
      fetchTeam(selectedTeam.id);
    } catch (err) {
      toast.error(err || p('deletingPlayerFailed'));
      fetchTeam(selectedTeam.id);
    }
  };

  const deleteGroup = async () => {
    groupHandleModal();
    setLoadingGroup(true);
    try {
      await Api.club.deleteSetting(groupDeleteID);
      getGroups();
    } catch (err) {
      toast.error(err || p('deletingGroupFailed'));
      getGroups();
    }
  };

 const fetchTransferTeams = async () => {
   try {
     const { data: _transferTeams } = await Api.teams.transferTeams();
     setTransferTeams(_transferTeams);
   } catch (e) {
     toast.error('Error fetching transfer teams');
   }
 };

 const selectTransferPlayer = (player) => {
   setSearchPlayers([]);
   setSelectedTransferPlayer(player);
 };

  useEffect(() => {
    if (selectedTeam) {
      fetchTeam(selectedTeam.id);
    }
    if (transferTeams.length === 0) {
      fetchTransferTeams();
    }
  }, [selectedTeam, transferTeams]);

  const renderPlayers = (active = true) => {
    if (teamLoader) {
      return (skeletonTableLoader(4, 8, 70));
    }

    return players.filter(player => !!player.active === active).map(player => (
      <tr key={`renderPlayers-${player.id}`}>
        <td><Link to={`/players/${selectedTeam?.id}/edit/${player.id}`}>{player.firstname} {player.lastname}</Link></td>
        <td>{player.position}</td>
        <td>{player.nationality}</td>
        <td>{player.jerseyNumber}</td>
        <td>{moment(player.dateOfBirth).format('YYYY')}</td>
        <td>{player.height}</td>
        <td>{player.weight}</td>
        <td>
          {!player.shoots && '-'}
          {player.shoots === 'left' && s('left')}
          {player.shoots === 'right' && s('right')}
        </td>

        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/players/${selectedTeam?.id}/edit/${player.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => playerHandleModal(player.id)}
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  const renderGroups = () => {
    return groups.map((group, index) => (
      <Draggable
        key={`group-${group.id}`}
        draggableId={`group-${group.id}`}
        index={index}
        isDragDisabled={!!groupState}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? 'rbdnd-dragging' : ''}
            key={`group-${group.id}`}
          >
            <td><div className="flex-Vcenter">{!groupState && <DragIcon color="#99999" />}{group.name}</div></td>
            <td>{group.values.join(',')}</td>
            <td>
              <UncontrolledDropdown className="dashboard__table-more">
                <DropdownToggle>
                  <DotsHorizontalIcon />
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem
                    onClick={() => setGroupState({ type: 'edit', ...group })}
                  >
                    {s('edit')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => groupHandleModal(group.id)}
                    className="danger"
                  >
                    {s('delete')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        )}
      </Draggable>
    ));
  };

  const groupOnDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const draggedGroup = groups[source.index];
    const newGroups = [...groups];
    newGroups.splice(source.index, 1);
    newGroups.splice(destination.index, 0, draggedGroup);
    setGroups(newGroups);
    setLoadingGroup(true);
    const order = newGroups.map((group, index) => ({
      id: group.id,
      order: index,
    }));
    try {
      await Api.club.updateSettingsOrder({ settingOrders: order });
      setLoadingGroup(false);
    } catch (err) {
      setLoadingGroup(false);
      toast.error(p('updatingOrderFailed'));
    }
  };

  const transfer = (team, player) => async () => {
    try {
      const res = await Api.players.transfer(team.id, player.id);
      if (res.statusText === 'OK' || (res.status >= 200 && res.status < 300)) {
        toast.success(`${player.fullname} transfered`);
        fetchTeam(team.id);
      }
    } catch {
      toast.error('Failed to transfer player');
    }
  };

  const debounce = (cb) => {
    let id = '';
    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        cb(value);
      }, 400);
    };
  };

  const db = debounce(async (value) => {
    if (value.length > 2) {
      try {
        const { data: searchedPlayers } = await Api.players.search(value, selectedTeam.id);
        setSearchPlayers(searchedPlayers);
        setSelectedTransferPlayer(undefined);
      } catch (e) {
        toast.error(p('searchFailed'));
      }
    } else {
      setSearchPlayers([]);
    }
  });

  const onChange = (value) => {
      db(value);
  };

  const getPlayerUrl = (player) => {
    if (player.imageUrl && player.imageCrop) {
      return `${player.imageUrl}.webp?crop=${player.imageCrop.x},${player.imageCrop.y},${player.imageCrop.width},${player.imageCrop.height}`;
    }
    return player.currentTeam.noPlayerImageUrl;
  };

  const onSubmitGroup = async (values) => {
    setLoadingGroup(true);
    setGroupState(false);
    const updatedPositions = values.values?.map((value) => value.value) || [];
    const val = values.values || [];
    try {
      await Promise.all(groups.filter((group) => group.id !== values.id).map((group) => {
        const vals = group.values.filter((value) => !updatedPositions.includes(value));
        return Api.club.editSetting({
          id: group.id,
          value: `${group.name}${vals.length > 0 ? `:${vals.join(':')}` : ''}`,
          order: group.order,
        });
      }));
      if (!values.id) {
        await Api.club.createSetting({
          key: 'playerGroup',
          value: `${values.name}${val.length > 0 ? `:${val.map(v => v.value).join(':')}` : ''}`,
        });
        toast.success(p('groupCreated'));
      } else {
        await Api.club.editSetting({
          id: values.id,
          value: `${values.name}${val.length > 0 ? `:${val.map(v => v.value).join(':')}` : ''}`,
          order: values.id,
        });
        toast.success(p('groupUpdated'));
      }
      getGroups();
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      getGroups();
    }
  };
  const PlayersCard = ({ active }) => {
    return (
      <>
        {active &&
          <>
            <div className="flex justify-content-end">
              <Link to={`/players/${selectedTeam?.id}/create`}><Button size="sm" color="primary">{p('createPlayer')}</Button></Link>
            </div>
            <div className="flex space-between">
              <div>
                <h3 className="page-title mb-0">{p('activePlayers')}</h3>
              </div>
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="icon icon--right mb-2" outline size="sm">{selectedTeam?.name || `${s('filter')}`} <ChevronDownIcon /></DropdownToggle>
                  <DropdownMenu>
                    {config.teams.map((team) => <DropdownItem key={`drpp${team.id}`} onClick={_setSelectedTeam(team)}>{team.name}</DropdownItem>)}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </>
        }
        {!active &&
          <div className="flex space-between">
            <div>
              <h3 className="page-title mb-0">{p('inactivePlayers')}</h3>
            </div>
          </div>
        }
        <div style={{ display: 'flex', paddingBottom: 5, marginBottom: 10, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>{s('name')}</th>
                <th>{s('position')}</th>
                <th>{s('nationality')}</th>
                <th>{s('shirtNumber')}</th>
                <th>{s('born')}</th>
                <th>{s('height')}</th>
                <th>{s('weight')}</th>
                <th>{s('shoots')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderPlayers(!!active)}
            </tbody>
          </Table>
        </div>
      </>
    );
  };
  return (
    <Container>
      <Loading loading={loadingPlayer || loadingGroup} />
      <DeleteModal
        visible={playerVisible}
        type="spilleren"
        handleModal={playerHandleModal}
        modalAction={deletePlayer}
      />
      <DeleteModal
        visible={groupVisible}
        type="gruppen"
        handleModal={groupHandleModal}
        modalAction={deleteGroup}
      />
      <NavTab
        tabs={[s('players'), p('playerSettings'), p('teamSettings')]}
        activeTab={tab}
        setTab={setTab}
      />
      {tab === '1' &&
        <div className="pb-2">
          <PlayersCard active />
          <PlayersCard />
        </div>
      }
      {tab === '2' &&
        <>
          <div className="flex space-between">
            <h3 className="page-title mb-2">{s('groups')}</h3>
            <Button className="mb-2" onClick={() => setGroupState({ type: 'create' })} size="sm" color="primary">{p('createGroup')}</Button>
          </div>
          {groupState ?
            (<Card><CardBody><GroupForm group={groupState} positions={positions} onSubmit={onSubmitGroup} cancel={() => setGroupState(false)} /></CardBody></Card>)
            : (
              <div style={{ display: 'flex', paddingBottom: 5, marginBottom: 10, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
                <DragDropContext onDragEnd={groupOnDragEnd}>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>{s('groupName')}</th>
                        <th>Mapped positions</th>
                        <th />
                      </tr>
                    </thead>
                    <Droppable droppableId="group-dnd">
                      {(provided) => {
                        return (
                          <>
                            <tbody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {renderGroups()}
                              {provided.placeholder}
                            </tbody>
                          </>
                        );
                      }}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </div>
            )
          }
          <Card>
            <CardHeader>{p('transferPlayers')}</CardHeader>
            <CardBody>
              <Row>
                <Col md={2}>
                  <Select
                    menuRenderer={({ options, onSelect }) => {
                      return (
                        <div className="Select-menu">
                          {options.map(option => (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                            <div className="Select-option" key={option.value} onClick={() => onSelect(option)}>
                              {option.label}
                              <img src={option.currentTeam.logoUrl} alt="" style={{ width: '20px', height: 'auto', float: 'right' }} />
                            </div>
                        ))}
                        </div>
                      );
                    }}
                    value={selectedTransferPlayer}
                    onChange={selectTransferPlayer}
                    onInputChange={onChange}
                    options={searchPlayers.map(player => (
                    {
                      ...player,
                      label: player.fullname,
                      value: player.id,
                    }
                  ))}
                  />
                </Col>
              </Row>
              { selectedTransferPlayer && (
                <Row style={{ marginTop: '20px' }}>
                  <Col md={6}>
                    <Toast>
                      <ToastHeader icon={<img src={getPlayerUrl(selectedTransferPlayer)} width="10" alt=""/>}>
                        {selectedTransferPlayer.fullname}
                      </ToastHeader>
                      <ToastBody>
                        <h3>{`${p('fromTeam')}: ${selectedTransferPlayer.currentTeam.name}`}</h3>
                        <h3>{`${p('toTeam')}: ${selectedTeam.name}`}</h3>
                        <Button color="primary" onClick={transfer(selectedTeam, selectedTransferPlayer)}>Transfer</Button>
                      </ToastBody>
                    </Toast>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </>
      }
      {tab === '3' &&
        <TeamProfileList />
      }
    </Container>
  );
};
export default PlayersListPage;
