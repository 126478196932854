import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import moment from 'moment';
import NavTab from '../../shared/components/custom/NavTab';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import OfferForm from './components/OfferForm';
import useConfig from '../../util/useConfig';
import { OfferState } from './context';
import tr, { partial } from '../../util/translation/translation';
import AlertModal, { AlertModalType } from '../../shared/components/custom/AlertModal';
import OfferTypes from './types';
import OfferContactForm from './components/OfferContactForm';

const OfferFormPage = (props) => {
  const { fetchLevels, fetchGroups, fetchCompanies, fetchTiers, createOffer, updateOfferById, contactOffer, updateScheduledMessage } = Api.offers;
  const config = useConfig();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [levels, setLevels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tab, setTab] = useState('');
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] = useState([]);
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] = useState(0);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [startDate, setStartDate] = useState();

  const s = partial('shared');
  const f = partial('OfferForm');
  const o = partial('OffersFormPage');
  const getLevels = useCallback(async () => {
    try {
      const results = await fetchLevels();
      setLevels(results);
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const getGroups = useCallback(async () => {
    try {
      const results = await fetchGroups();
      setGroups(results);
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const getCompanies = useCallback(async () => {
    try {
      const results = await fetchCompanies();
      setCompanies(results.map(company => ({ id: company.id, label: company.name, value: company.id })));
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const getTiers = useCallback(async () => {
    try {
      const results = await fetchTiers(config);
      setTiers(results);
    } catch (error) {
      toast.error(error);
    }
  }, []);

  const getScheduleMessagesByServiceId = (async (page = 1, pageSize = 10) => {
    try {
      let messageByEventId = [];

      if (messageStatus === tr('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceId(currentOffer.id, page, pageSize);
      } else {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(currentOffer.id, page, pageSize, messageStatus);
      }
      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total);
      }

      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
    } catch (error) {
      toast.error(error);
    }
  });

  useEffect(() => {
    Promise.allSettled([getLevels(), getGroups(), getCompanies(), getTiers()]).then(() => setLoading(false));
  }, [getLevels, getGroups, getCompanies, getTiers]);

  useEffect(() => {
    getScheduleMessagesByServiceId();
  }, [currentOffer, messageStatus]);

  const handleSubmit = async (values) => {
    const clone = window.location.pathname.split('/')[3] === 'clone';
    const companyId = values.company && !clone ? values.company.id : null;
    console.log(clone, companyId, currentOffer);
    let companyContactPersonId;
    if (typeof values.contactPerson === 'string') {
      companyContactPersonId = values.contactPerson;
    } else if (values.contactPerson?.value) {
      companyContactPersonId = values.contactPerson.value;
    } else {
      companyContactPersonId = null;
    }
    const getPromoCode = (promoType, vals) => {
      switch (promoType) {
        case 'code':
          return vals.promoCode;

        case 'qrstring':
          return vals.promoQrString;

        case 'link':
          return vals.promoLink;

        default:
          return null;
      }
    };

    let tiersArray = [];

    if (values.targetAudience === 'all') {
      tiersArray = [
        {
          tierId: null,
          name: 'All users',
        },
      ];
    } else if (values.tier?.length > 0) {
      tiersArray = values.tier.map(t => ({ tierId: t.value, name: t.label }));
    }
    const payload = {
      title: values.title,
      subtitle: values.subtitle,
      description: values.description,
      category: values.category.value,
      price: values.ordinaryPrice ? Number(values.ordinaryPrice) : 0,
      discountedPrice: values.discountedPrice ? Number(values.discountedPrice) : 0,
      discountedPercentage: values.discountedPercentage ? Number(values.discountedPercentage) : 0,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      isPrivate: values.isPrivate,
      levels: values.level?.length > 0 ? values.level.map(l => ({ levelId: l.value, name: l.label })) : [],
      groups: values.group?.length > 0 ? values.group.map(g => ({ groupId: g.value, name: g.label })) : [],
      tiers: tiersArray,
      targetAudience: values.targetAudience === 'all' ? 'both' : values.targetAudience,
      isVisible: values.isVisible,
      companyId,
      companyContactPersonId,
      websiteUrl: values.promoType === 'link' ? values?.promoLink : null,
      promoType: values.promoType === 'none' ? null : values.promoType,
      activationLimit: values.promoType !== 'none' && values.promoType !== 'link' ? parseInt(values?.activationLimit, 10) || null : null,
      isPinned: values?.isPinned,
      shouldNotify: values.shouldNotify,
    };
    if (values.promoType !== 'imageurl') {
      payload.promoCode = getPromoCode(values.promoType, values);
    }
    if (payload.levels.length === 0 && payload.groups.length === 0) {
      if (payload.targetAudience === OfferTypes.TargetAudience.partner) {
        setErrorMessage(f('levelOrGroupRequiredMessage'));
        setShowPermissionModal(true);
        return;
      }
    }
    if (payload.targetAudience === OfferTypes.TargetAudience.private && payload.tiers.length === 0) {
      setErrorMessage(f('levelOrGroupRequiredMessage'));
      setShowPermissionModal(true);
      return;
    }
    if (values.offerType === '1' && companyContactPersonId === null && payload.targetAudience === OfferTypes.TargetAudience.partner) {
      setErrorMessage(f('contactRequired'));
      setShowPermissionModal(true);
      return;
    }

    try {
      setLoading(true);
      if (!currentOffer || clone) {
        const { data } = await createOffer(payload, config.id);
        const imagePromises = [values.imageUrl?.promise?.('offer', 'imageUrl', data.id)];
        if (payload.promoType === 'imageurl') {
          imagePromises.push(values.promoImageUrl?.promise?.('offer', 'promoCode', data.id));
        }
        await Promise.all(imagePromises);
        await values.imageUrl?.promise?.('offer', 'imageUrl', data.id);
        toast.success(o(`${'offerHasBeenCreated'}`));
      } else {
        await updateOfferById(currentOffer.id, payload, config.id);
        const imagePromises = [values.imageUrl?.promise?.('offer', 'imageUrl', currentOffer.id)];
        if (payload.promoType === 'imageurl') {
          imagePromises.push(values?.promoImageUrl?.promise?.('offer', 'promoCode', currentOffer.id));
        }
        await Promise.all(imagePromises);
        toast.success(o(`${'offerHasBeenUpdated'}`));
      }
      setLoading(false);
      props.history.push('/offers');
    } catch (err) {
      console.error(err);
      if (err?.data?.message === 'OfferForm.NameInUse') {
        setErrorMessage(tr('ErrorMessage.nameAlereadyExists'));
        setShowPermissionModal(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      toast.error(s(`${'somethingWentWrong'}`));
    }
  };

  const handleContact = async (e, state, change) => {
    e.preventDefault();
    const { title, message, type, timeToSend, notificationLabel } = state.form.offers_contact_form.values;
    const scheduledDate = timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null;
    let contactTiers = [];
    let contactLevels = [];
    let contactGroups = [];
    const contactUserGroups = [];
    if (currentOffer.tiers.length > 0) {
      if (currentOffer.tiers.length === 1 && currentOffer.tiers[0].name === OfferTypes.OfferContact.allTiers) {
        contactTiers.push(OfferTypes.OfferContact.allTiers);
      } else if (currentOffer.tiers[0].name === 'All users') {
        contactUserGroups.push('all groups');
      } else {
        contactTiers = currentOffer.tiers.map(tier => tier.tierId);
      }
    }
    if (currentOffer.levels.length > 0) {
      if (currentOffer.levels.length === 1 && currentOffer.levels[0].name === OfferTypes.OfferContact.allLevels) {
        contactLevels.push(OfferTypes.OfferContact.allLevels);
      } else {
        contactLevels = currentOffer.levels.map(level => level.name);
      }
    }
    if (currentOffer.groups.length > 0) {
      if (currentOffer.groups.length === 1 && currentOffer.groups[0].name === OfferTypes.OfferContact.allGroups) {
        contactGroups.push(OfferTypes.OfferContact.allGroups);
      } else {
        contactGroups = currentOffer.groups.map(group => group.name);
      }
    }
    try {
      const mainPayload = {
        title,
        message,
        type,
        tiers: contactTiers,
        userGroups: contactUserGroups,
        levels: contactLevels,
        groups: contactGroups,
        notificationLabel: notificationLabel?.value || null,
      };
      if (timeToSend === 'SCHEDULED' && scheduleMessageID) {
        await updateScheduledMessage(currentOffer.id, scheduleMessageID, config.id, { ...mainPayload, scheduledDate });
        toast.success(`${tr('ScheduledMessagesPage.scheduleMessageUpdated')}`);
      } else if (timeToSend === 'INSTANT') {
        await contactOffer(currentOffer.id, config.id, mainPayload);
      } else {
        await contactOffer(currentOffer.id, config.id, { ...mainPayload, scheduledDate });
        toast.success(`${tr('ScheduledMessagesPage.messageScheduled')}`);
      }
      if (timeToSend === 'INSTANT') {
        if (type === OfferTypes.CommunicationTypes.push) {
          toast.success(o(`${'pushNotificationSent'}`));
        } else if (type === OfferTypes.CommunicationTypes.email) {
          toast.success(o(`${'emailSent'}`));
        } else {
          toast.success(o(`${'smsSent'}`));
        }
      }
    } catch (_e) {
      toast.error(s(`${'somethingWentWrong'}`));
    }
    getScheduleMessagesByServiceId();
    setScheduleMessageID(null);
    change('offers_contact_form', 'title', '');
    change('offers_contact_form', 'message', '');
    change('offers_contact_form', 'type', 'email');
    change('offers_contact_form', 'timeToSend', 'INSTANT');
    change('offers_contact_form', 'scheduleTime', '');
  };

  return (
    <Container>
      <AlertModal visible={showPermissionModal} modalType={AlertModalType.ERROR} handleModal={() => setShowPermissionModal(false)} message={errorMessage} />
      <Loading loading={loading} />
      <Row>
        <Col>
          <p className="page-title">{o('createOffer')}</p>
        </Col>
      </Row>
      <Card>
        <OfferState>
          <CardBody style={{ padding: 0 }}>
            <NavTab
              tabs={[
                s('details'),
                {
                  tab: (
                    <>
                      {s('communication')}
                      {numberOfPendingMessagesPerEvent > 0 && <Badge style={{ marginLeft: 5 }} color="primary">{numberOfPendingMessagesPerEvent}</Badge>}
                    </>
                  ),
                  disabled: props.match.path === '/offers/create',
                }]}
              activeTab={tab}
              setTab={setTab}
              size="sm"
            />
            <TabContent activeTab={tab}>
              <TabPane tabId="1">
                <OfferForm
                  onSubmit={handleSubmit}
                  levels={levels}
                  groups={groups}
                  companies={companies}
                  tiers={tiers}
                  setCurrentOffer={setCurrentOffer}
                  scheduledPendingMessagesForEvent={numberOfPendingMessagesPerEvent}
                />
              </TabPane>
              <TabPane tabId="2">
                <OfferContactForm
                  onSubmit={handleContact}
                  offer={currentOffer}
                  scheduleMessageID={scheduleMessageID}
                  setScheduleMessageID={setScheduleMessageID}
                  setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                  scheduleMessagesForEvent={scheduleMessagesForEvent}
                  getScheduleMessagesByServiceId={getScheduleMessagesByServiceId}
                  totalAmountOfScheduledMessages={totalAmountOfScheduledMessages}
                  messageStatus={messageStatus}
                  setMessageStatus={setMessageStatus}
                  levels={levels}
                  groups={groups}
                  tiers={tiers}
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </OfferState>
      </Card>
    </Container>
  );
};

export default withRouter(OfferFormPage);
