import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import Pagination from '../../shared/components/pagination/Pagination';
import t, { partial } from '../../util/translation/translation';
import renderField from '../../shared/components/custom/Field';
import renderSelectField from '../../shared/components/form/Select';

const ParticipantStatsPage = ({ change }) => {
  const [participantStats, setParticipantStats] = useState([]);
  const [loadingStats, setLoadingStats] = useState(true);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [search, setSearch] = useState('');
  const [eventCategories, setEventCategories] = useState('');
  const [selectedEventCategory, setSelectedEventCategory] = useState(null);
  const [order, setOrder] = useState({
    orderBy: 'companyName',
    desc: false,
    startDate: moment().subtract(1, 'month').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().format('YYYY-MM-DDT23:59:00'),
  });
  const s = partial('shared');
  const epp = partial('EventParticipantsPage');
  const ep = partial('EventsPage');

  const getEligibleCompanies = (event, clubCompanies) => {
    if (!event.accessCategories?.length && !event.accessLevels?.length) {
      return clubCompanies;
    }

    return clubCompanies.filter(company => {
      const hasMatchingGroup = event.accessCategories?.some(accessCategory =>
        company.groups?.some(group => group.fieldValue === accessCategory),
      );

      const hasMatchingLevel = event.accessLevels?.flat().some(accessLevel =>
        company.companyLevel === accessLevel,
      );

      return hasMatchingGroup || hasMatchingLevel;
    });
  };

  const getParticipantStats = (events, companies, statSearch, statSelectedEventCategory) => {
    const filteredCompanies = companies.filter(company =>
      !statSearch || company.name.toLowerCase().includes(statSearch.toLowerCase()),
    );

    const filteredEvents = events.filter(event =>
      !statSelectedEventCategory || event.category?.id === statSelectedEventCategory,
    );

    return filteredCompanies.map(company => {
      const stats = {
        companyName: company.name,
        companyId: company.id,
        totalFriends: 0,
        totalAvailableSpots: 0,
        totalAttended: 0,
      };

      const eligibleEvents = filteredEvents.filter(event =>
        getEligibleCompanies(event, [company]).length > 0,
      );

      const eventIds = eligibleEvents.map(e => e.id);
      console.log('eventIds--', eventIds);

      eligibleEvents.forEach(event => {
        const availableSpots = event.category
          ? event.category.maxAccountAttendees
          : event.maxAccountAttendees;

        stats.totalAvailableSpots += availableSpots || 0;

        const companyParticipants = event.participants.filter(p =>
          p.companyId === company.id,
        );

        const companyParticipants1 = event.participants.filter(p =>
          p.companyId === '38e2c1ea-3bb7-4808-aa8b-e46ec484e2ef',
        );
        console.log('companyParticipants1----', companyParticipants1);
        companyParticipants.forEach(participant => {
          stats.totalFriends += (participant.friendAmount || 0) + 1;
          stats.totalAttended += participant.status === 'ATTENDED' ? (participant.friendAmount || 0) + 1 : 0;
        });
      });

      return stats.totalFriends === 0 &&
             stats.totalAvailableSpots === 0 &&
             stats.totalAttended === 0 ? null : stats;
    }).filter(Boolean);
   };

  const fetchParticipantStats = async (currentPage = 1, pageSize = 10, orderObject = {
    orderBy: 'companyName',
    desc: false,
    startDate: moment().subtract(1, 'month').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().format('YYYY-MM-DDT23:59:00'),
    eventCategory: null,
  }) => {
    setLoadingStats(true);
    try {
      const [resEventCategories, clubCompanies, resEvents] = await Promise.all([
        Api.events.getEventCategories(),
        Api.companies.getClubCompanies(),
        Api.events.all(),
      ]);

      const filteredEvents = resEvents.data.filter((event) =>
        moment(event.endDate) > moment(orderObject.startDate) &&
        moment(event.endDate) < moment(orderObject.endDate),
      );

      const stats = getParticipantStats(filteredEvents, clubCompanies, search, orderObject.eventCategory);

      const sortedStats = [...stats].sort((a, b) => {
        const valA = a[orderObject.orderBy];
        const valB = b[orderObject.orderBy];
        // eslint-disable-next-line no-nested-ternary
        return orderObject.desc
          ? (typeof valA === 'string' ? valB.localeCompare(valA) : valB - valA)
          : (typeof valA === 'string' ? valA.localeCompare(valB) : valA - valB);
      });

      const start = (currentPage - 1) * pageSize;
      const paginatedStats = sortedStats.slice(start, start + pageSize);

      setEventCategories([{
        label: s('all'),
        value: null,
      }, ...resEventCategories.data.map(({ id, title }) => ({
        value: id,
        label: title,
      }))]);

      setParticipantStats(paginatedStats);
      setPagination(old => ({ ...old, total: sortedStats.length }));
      setLoadingStats(false);
    } catch (err) {
      toast.error(t('CompanyFormPage.companyInfoFetchingERROR'));
      setLoadingStats(false);
    }
  };

  useEffect(() => {
    change('endDate', moment().format('YYYY-MM-DD'));
    change('startDate', moment().subtract(1, 'month').format('YYYY-MM-DD'));
    change('category', {
      label: '',
      value: null,
    });

    fetchParticipantStats();
  }, []);

  const onChangePage = (pager) => {
    if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
      setPagination(old => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      fetchParticipantStats(pager.currentPage, pager.pageSize, {
        ...order,
        eventCategory: selectedEventCategory,
      });
    }
  };

  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ?
      <ArrowDownIcon size={16} />
      :
      <ArrowUpIcon size={16} />;
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
      startDate: order.startDate,
      endDate: order.endDate,
      eventCategory: selectedEventCategory,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    fetchParticipantStats(1, 10, newOrder);
  };
  const handleSearch = () => {
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    fetchParticipantStats(1, 10, {
      ...order,
      eventCategory: selectedEventCategory,
    });
  };

  const renderParticipantStats = () => {
    return participantStats.map((stat, i) => (
      <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`company-${stat.companyId}`}>
        <td>
          <div className="flex-Vcenter">
            <Link to={`/companies/edit/${stat.companyId}?tab=1`}>{stat.companyName}</Link>
          </div>
        </td>
        <td>{stat.totalAvailableSpots}</td>
        <td>{stat.totalFriends}</td>
        <td>{stat.totalAttended}</td>
      </tr>
    ));
  };
  return (
    <Container>
      <Loading loading={loadingStats} />
      <div className="card__title m-0">
        <form className="form">
          <Row>
            <Col>
              <div className="form__form-group">
                <span className="form-header">{s('from')}</span>
                <div className="form__form-group-field">
                  <Field name="startDate" component={renderField} type="date" onChange={(e) => setOrder((prev) => ({ ...prev, startDate: e.target.value }))} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form-header">{s('to')}</span>
                <div className="form__form-group-field">
                  <Field name="endDate" component={renderField} type="date" onChange={(e) => setOrder((prev) => ({ ...prev, endDate: e.target.value }))} />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form">{s('search')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="search"
                    component={renderField}
                    placeholder={s('companyName')}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <span className="form">{s('category')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="category"
                    placeholder={s('category')}
                    options={eventCategories}
                    component={renderSelectField}
                    onChange={(e) => setSelectedEventCategory(e.value)}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="form__form-group">
                <div className="form__form-group-field" style={{ marginTop: 20 }}>
                  <Button type="button" color="primary" size="sm" onClick={handleSearch}>{s('filter')}</Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <Row>
        <Col>
          <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
            <Table responsive striped >
              <thead>
                <tr>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('companyName')}>
                    <div className="flex alignItems-center">{s('companyName')} {renderSort('companyName')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('totalAvailableSpots')}>
                    <div className="flex alignItems-center">{ep('availableSeats')} {renderSort('totalAvailableSpots')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('totalFriends')}>
                    <div className="flex alignItems-center">{ep('signedUp')} {renderSort('totalFriends')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('totalAttended')}>
                    <div className="flex alignItems-center">{epp('attended')} {renderSort('totalAttended')}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderParticipantStats()}
              </tbody>
            </Table>
          </div>
          <Pagination
            items={pagination.total}
            onChangePage={onChangePage}
            initialPage={pagination.currentPage}
          />
        </Col>
      </Row>
    </Container>
  );
};
const reduxF = reduxForm({
  form: 'ParticipantStatsForm',
});

export default reduxF(ParticipantStatsPage);
