import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DragIcon from 'mdi-react/DragIcon';
import { Badge, Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useConfig from '../../../util/useConfig';
import TeamProfileForm from './TeamProfileForm';
import Api from '../../../util/api';
import t, { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';

const s = partial('shared');
const p = partial('TeamProfileList');

const TeamProfileList = () => {
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [isOpen, setOpen] = useState(undefined);

  const handleTeamSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = await Api.clubadmin.profile.updateTeam({
        ...values,
        matchCenterUrl: values.matchCenterUrl || null,
        standingsUrl: values.standingsUrl || null,
        playerStatsUrl: values.playerStatsUrl || null,
        imageUrl: values.imageUrl ? values.imageUrl[0].value : undefined,
        matchCenterVisibility: values.matchCenterVisibility,
        appVisibility: values.appVisibility,
      });
      setSelectedTeam(data);
      setTeams(config.teams.map((team) => {
        if (team.id === values.id) {
          return { ...team,
            matchCenterVisibility: values.matchCenterVisibility,
            appVisibility: values.appVisibility,
        };
}
      return team;
      }));
      setOpen(false);
      toast.success(p('updateSuccess'));
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const draggedPrize = teams[source.index];
    const newTeams = [...teams];
    newTeams.splice(source.index, 1);
    newTeams.splice(destination.index, 0, draggedPrize);
    setTeams(newTeams);
    await Api.clubadmin.profile.updateTeamOrders(newTeams.map((team, index) => ({
      id: team.id,
      order: index,
    })));
  };

  useEffect(() => {
    if (config && config.teams?.length > 0) {
      setTeams(config.teams);
    }
  }, [config]);

  return (
    <>
      <Loading loading={loading} />
      <div className="flex space-between mb-2">
        <div>
          <h3 className="page-title mb-0">{p('managedTeams')}</h3>
        </div>
      </div>
      <div style={{ display: 'flex', paddingBottom: 5, marginBottom: 10, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th />
                <th>{s('name')}</th>
                <th>{t('SponsorForm.playerCard')}</th>
                <th>{t('AppLayout.matchcenterTile')}</th>
              </tr>
            </thead>
            <Droppable droppableId="team-order">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {teams.map((team, index) => (
                    <Draggable key={`teamprofileid${team.id}`} index={index} draggableId={`teamprofileid${team.id}`}>
                      {(_provided, snapshot) => (
                        <tr onClick={() => { setSelectedTeam(team); setOpen(true); }} key={`teamprofileid${team.id}`} {..._provided.draggableProps} {..._provided.dragHandleProps} ref={_provided.innerRef} className={snapshot.isDragging ? 'rbdnd-dragging' : ''}>
                          <td><DragIcon color="#999999" /></td>
                          <td>{team.name}</td>
                          <td>
                            {team.appVisibility ?
                              <Badge className="m-1" color="success">
                                {s('show')}
                              </Badge> :
                              <Badge className="m-1" color="warning">
                                {s('hide')}
                              </Badge>
                        }
                          </td>
                          <td>
                            {team.matchCenterVisibility ?
                              <Badge className="m-1" color="success">
                                {s('show')}
                              </Badge> :
                              <Badge className="m-1" color="warning">
                                {s('hide')}
                              </Badge>
                        }
                          </td>
                        </tr>
                      )
                      }
                    </Draggable>

              ))}
                </tbody>
            )}
            </Droppable>
          </Table>
        </DragDropContext>
      </div>
      { selectedTeam && isOpen && <TeamProfileForm isOpen={isOpen} setOpen={setOpen} team={selectedTeam} onSubmit={handleTeamSubmit} />}
    </>
  );
};

export default TeamProfileList;
