export default {
  club_name: 'Odds BK',
  club_name_short: 'oddsbk',
  stadium_name: '',
  staging_url: 'https://oddsbk.api.fangroup.io',
  prod_url: 'https://oddsbk.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/oddsbk',
  club_logo: '/assets/teams/oddsbk.png',
  app_name: 'oddsbk',
  websale_name: 'oddsbk',
  ntb_team_id: 10060,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'oddsbk',
  monolithEnabled: false,
};
