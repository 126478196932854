import React, { useEffect } from 'react';
import { Col, Card, Row, Button } from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { partial } from '../../../util/translation/translation';
import renderField from '../../../shared/components/custom/Field';
import validate from './validate';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';

const s = partial('shared');
const p = partial('UserTiers');

const UserTierForm = ({
  handleSubmit,
  tier,
  initialize,
  formValues,
}) => {
  useEffect(() => {
    if (tier) {
      const {
        createdAt, updatedAt, deletedAt, originalUrl, features, cardUrl, prices, description, ...tierValues
      } = tier;

      const initObject = {
        ...tierValues,
        description,
        image: originalUrl
          ? {
            name: 'image',
            preview: originalUrl,
          }
          : null,
          cardBackground: cardUrl
          ? {
            name: 'image',
            preview: cardUrl,
          }
          : null,

        pickedFeatures: features,
      };
      let completeObject = { ...initObject };
      if (prices.length) {
        completeObject = {
          ...completeObject,
          ...{
            price1: prices[0],
            // price3: prices[1],
            // price6: prices[2],
            price12: prices[3],
          },
        };
      }
      initialize(completeObject);
    }
  }, [tier]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Card>
        <Row>
          <Col lg={7} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('tierName')}</span>
                  <div className="marginTop-5" />
                  <Field
                    name="name"
                    disabled={tier?.name}
                    component={renderField}
                    placeholder={p('tierName')}
                  />
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('tierIcon')} (200px/200px)</span>
                  <Field name="image" component={renderDropZoneField} crop="TIER_CROP" />
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('cardBackground')} (16/9)</span>
                  <Field name="cardBackground" component={renderDropZoneField} crop="LANDSCAPE" />
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('description')}</span>
                  <div className="marginTop-5" />
                  <div className="form__form-group form__form-group-id">
                    <Field
                      name="description"
                      component={TextEditor2}
                    />
                  </div>
                </div>
              </Col>
              {/* <Col lg={6} md={12} sm={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('previousPrice')}</span>
                  <div className="marginTop-5" />
                  <Field
                    name="previousPrice"
                    component={renderField}
                    placeholder={p('previousPrice')}
                    type="number"
                    disabled={!isPriceEditable}
                  />
                </div>
              </Col> */}
            </Row>
          </Col>
          <Row>
            {/* <Col lg={3} md={12} sm={12}>
              <div className="form__form-group">
                <span className="form-header">{p('oneMonthPrice')}</span>
                <div className="marginTop-5" />
                <Field
                  name="price1"
                  component={renderField}
                  placeholder="One month price"
                  type="number"
                  disabled={!isPriceEditable}
                />
              </div>
            </Col> */}
            {/* <Col lg={3} md={12} sm={12}>
              <div className="form__form-group">
                <span className="form-header">Three month price</span>
                <div className="marginTop-5" />
                <Field
                  name="price3"
                  component={renderField}
                  placeholder="Three month price"
                  type="number"
                  disabled={!isPriceEditable}
                />
              </div>
            </Col>
            <Col lg={3} md={12} sm={12}>
              <div className="form__form-group">
                <span className="form-header">Six month price</span>
                <div className="marginTop-5" />
                <Field
                  name="price6"
                  component={renderField}
                  placeholder="Six month price"
                  type="number"
                  disabled={!isPriceEditable}
                />
              </div>
            </Col> */}
            {/* <Col lg={3} md={12} sm={12}>
              <div className="form__form-group">
                <span className="form-header">{p('yearlyPrice')}</span>
                <div className="marginTop-5" />
                <Field
                  name="price12"
                  component={renderField}
                  placeholder="One year price"
                  type="number"
                  disabled={!isPriceEditable}
                />
              </div>
            </Col> */}
          </Row>
          {/*
          <DragNDropField
            name="pickedFeatures"
            label={p('tierScreens')}
            options={availableFeatures}
            initialValue={tier?.features}
          /> */}
          <Col lg={5} md={12} sm={12} xs={12}>
            <Row>
              <Col className="ml-3">
                <span className="form-header">App preview</span>
                <div
                  style={{ display: 'flex', borderRadius: 8, padding: 15, backgroundImage: `url(${formValues?.cardBackground?.croppedImage || formValues?.cardBackground?.preview})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: '#000', width: 320, height: 320 * 0.5625 }}
                >
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <span style={{ color: '#fff', fontSize: 20 }}>{formValues?.name}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <span style={{ color: '#fff', fontSize: 10 }}>{s('firstname')} {s('lastname')}</span>
                        <span style={{ color: '#fff', fontSize: 16 }}>John Doe</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <span style={{ color: '#fff', fontSize: 10 }}>{s('expirationDate')}</span>
                        <span style={{ color: '#fff', fontSize: 16 }}>1 Dec, 2025</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginTop: '15px' }}>
            <Button type="submit" color="primary">
              {s('save')}
            </Button>
            <Link
              className="color-unset"
              to="/user_tiers"
            >
              <Button type="button">
                {s('close')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </form>
  );
};
const reduxF = reduxForm({
  form: 'user-tier-form',
  validate,
});
const mapStateToProps = (state) => ({
  formValues: getFormValues('user-tier-form')(state),
});

export default connect(mapStateToProps)(reduxF(UserTierForm));

