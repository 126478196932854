import React, { useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import { reduxForm, Field, propTypes as reduxFormProps, getFormValues } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import coverToolTipImg from '../../../assets/header-img.png';
import partnerToolTipImg from '../../../assets/partner-img.png';
import coverToolTipFullImg from '../../../assets/full-header-img.png';
import partnerToolTipFullImg from '../../../assets/full-partner-img.png';
import { getImageHash } from '../../../util/functions';

const s = partial('shared');
const p = partial('SponsorForm');
const SponsorForm = ({ handleSubmit, company, initialize, formValues }) => {
  const constructData = (fieldName, itemName) => company.images[fieldName]
  ? {
      name: itemName,
      preview: company.images[fieldName],
      hash: getImageHash(company.images[fieldName]),
    }
  : null;
  /* eslint-disable indent */
  useEffect(() => {
    if (company && company.id) {
      initialize({
        companyImage: constructData('companyImage', 'company-logo'),
        appSponsorImage: constructData('appSponsorImage', 'app-sponsor-logo'),
        appTicketImage: constructData('appTicketImage', 'app-ticket-image'),
        coverImage: constructData('coverImage', 'cover-image'),
        playerImage: constructData('playerImage', 'app-player-image'),
        printImage: constructData('printImage', 'app-print-image'),
      });
    }
  }, [company]);
  return (
    <>
      <Col className="mb-5">
        <h4 className="bold text-dark">{p('uploadImages')}</h4>
        <p>
          {p('uploadImagesInfo')}
          <br />
          {p('uploadImagesInfo2')}
        </p>
      </Col>

      <form onSubmit={handleSubmit} className="form">
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyImageInApp')}
              <TooltipWithIcon
                text={partnerToolTipImg}
                id="profileImage"
                fullImageUrl={partnerToolTipFullImg}
                isImageUrl
              />
              <p>
                {p('companyImageInAppInfo')}
                <br />
                {p('recommendedImgSizeCompanyLogo')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="companyImage" maxSize={0.1} component={renderDropZoneField} crop="PROFILE_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="w-100">
            {formValues.companyImage && !formValues.companyImage?.remove && <img className="company-logo" src={formValues.companyImage.croppedImage || formValues.companyImage?.preview} alt="" />}
          </div>
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('companyCoverImageInApp')}
              <TooltipWithIcon text={coverToolTipImg} id="coverImage" fullImageUrl={coverToolTipFullImg} isImageUrl />
              <p>
                {p('companyCoverImageInAppInfo')}
                <br />
                {p('recommendedImgSizeCompanyCover')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="coverImage" maxSize={3} component={renderDropZoneField} crop="BANNER_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.coverImage && !formValues.coverImage?.remove && <img className="company-cover" src={formValues.coverImage.croppedImage || formValues.coverImage?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('matchSponsor')}
              <p>
                {p('imageOverTicketValidation')}
                <br />
                {p('recommendedImgSizeMatchSponsor')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="appTicketImage" maxSize={3} component={renderDropZoneField} crop="MATCHSPONSOR_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.appTicketImage && !formValues.appTicketImage?.remove && <img className="sponsor-app sponsor-app-ticket" src={formValues.appTicketImage.croppedImage || formValues.appTicketImage?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('playerCard')}
              <p>
                {p('playerImage')}
                <br />
                {p('recommendedImgSizePlayerImage')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              name="playerImage"
              maxSize={3}
              component={renderDropZoneField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PLAYERCARD_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.playerImage && !formValues.playerImage?.remove && <img className="sponsor-player bg-dark" src={formValues.playerImage.croppedImage || formValues.playerImage?.preview} alt="" />}
        </Col>

        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>

        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('printedPaperTicket')}
              <p>
                {p('boxOffice&WebSalesImage')}
                <br />
                {p('recommendedImgSizePrintedPaperTicket')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field
              maxSize={3}
              name="printImage"
              component={renderDropZoneField}
              style={{ width: 'auto', height: 'auto' }}
              crop="PRINTEDPAPERTICKET_CROP"
            />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          {formValues.printImage && !formValues.printImage?.remove && <img className="sponsor-print" src={formValues.printImage.croppedImage || formValues.printImage?.preview} alt="" />}
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <span className="form-header bold">
              {p('appSponsorInApp')}
              <p>
                {p('appSponsorLogoInAppInfo')}
                <br />
                {p('recommendedImgSizeAppSponsorLogo')}
              </p>
            </span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div>
            <span className="form-header marginRight-8 bold">{p('simplifiedPreview')}</span>
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="form__form-group">
            <Field name="appSponsorImage" maxSize={3} component={renderDropZoneField} crop="APP_SPONSOR_CROP" />
          </div>
        </Col>
        <Col xl={6} lg={12}>
          <div className="w-100">
            {formValues.appSponsorImage && !formValues.appSponsorImage?.remove && <img className="app-sponsor-logo" src={formValues.appSponsorImage.croppedImage || formValues.appSponsorImage?.preview} alt="" />}
          </div>
        </Col>
        <Col xl={12} lg={12} className="my-2">
          <hr />
        </Col>
        <Col>
          <Button color="primary">{p('saveSponsor')}</Button>
          <Link to="/companies">
            <Button color="secondary">{s('cancel')}</Button>
          </Link>
        </Col>
      </form>
    </>
  );
};

SponsorForm.propTypes = {
  ...reduxFormProps,
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    app: PropTypes.shape({
      preview: PropTypes.string,
    }),
    print: PropTypes.shape({
      preview: PropTypes.string,
    }),
  }),
};

SponsorForm.defaultProps = {
  company: null,
  formValues: {},
};

const reduxF = reduxForm({
  form: 'company_sponsor_form',
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('company_sponsor_form')(state),
});

export default connect(mapStateToProps)(reduxF(SponsorForm));
