import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Table, ButtonToolbar, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import useModal from '../../shared/hooks/useModal';
import { partial } from '../../util/translation/translation';
import { formatDateTime } from '../../util/date-and-time';

const PackagesPage = () => {
  const [packages, setPackages] = useState([]);
  const [packagesLoading, setPackagesLoading] = useState(true);
  const [handleModal, visible, deleteID] = useModal();
  const p = partial('PackagesPage');
  const s = partial('shared');

  const getPackages = async () => {
    setPackagesLoading(true);
    try {
      const resPackages = await Api.packages.getPackages();
      setPackages(resPackages.data);
      setPackagesLoading(false);
    } catch (err) {
      toast.error(err || p('fetchingPackagesFailed'));
      setPackagesLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  const deletePackage = async () => {
    setPackagesLoading(true);
    try {
      await Api.packages.deletePackage(deleteID);
      toast.success(p('packageDeleted'));
    } catch (err) {
      toast.error(err || p('deletingPackageFailed'));
    }
    handleModal();
    getPackages();
  };

  const renderPackages = () => {
    return packages.map(pack => (
      <tr key={`renderPackages-${pack.id}`}>
        <td><Link to={`/packages/edit/${pack.id}`}>{pack.package.name}</Link></td>
        <td>{formatDateTime(pack.package.sale_start)}</td>
        <td>{formatDateTime(pack.package.sale_end)}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/packages/edit/${pack.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                className="danger"
                onClick={() => handleModal(pack.id)}
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  return (
    <Container>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        modalAction={deletePackage}
        type={s('package')}
      />
      <Row>
        <Col md={12}>
          <h3 className="page-title">{s('packages')}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Loading loading={packagesLoading} />
          <Card>
            <CardBody>
              <div className="card__title marginTop-0">
                <div className="tableShowNews">
                  <p className="bold-text margin-0">{s('packages')}</p>
                </div>
                <ButtonToolbar className="margin-top-0">
                  <Link className="btn btn-primary btn-sm" to="/packages/create">{p('createPackage')}</Link>
                </ButtonToolbar>
              </div>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>{s('name')}</th>
                    <th>{s('saleStart')}</th>
                    <th>{s('saleEnd')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {renderPackages()}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PackagesPage;
