/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Button, Badge, CardTitle, Card, CardBody, Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getFormValues, isValid, touch } from 'redux-form';
import { connect } from 'react-redux';
import CardVariantIcon from 'mdi-react/CardsVariantIcon';
import BookOpenVariantIcon from 'mdi-react/CardTextOutlineIcon';
import NewsForm from './components/NewsForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t, { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import AccessForm from './components/AccessForm';
import useConfig from '../../util/useConfig';
import NewsComponentsForm from './components/NewsComponentsForm';
import { componentsFromState } from './components/newsUtils';
import NewsContactForm from './components/NewsContactForm';
import OfferTypes from '../offers/types';
import LikesForm from './components/LikesList';
import AppNewsCardPreview from './components/AppNewsCardPreview';
import AppNewsPagePreview from './components/AppNewsPagePreivew';

const p = partial('NewsFormPage');
const s = partial('shared');
const o = partial('OffersFormPage');

const NewsFormPage = (props) => {
  const { newsID } = useParams();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const [previewType, setPreviewType] = useState('CARD');
  useEffect(() => {
    const defaultTab = history.location.search.includes('tab') ? history.location.search.replace('?tab=', '') : '1';
    if (defaultTab !== activeTab) {
      setActiveTab(defaultTab);
      setPreviewType(defaultTab === '2' ? 'POST' : 'CARD');
    }
  }, [history.location.search]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setPreviewType(tab === '2' ? 'POST' : 'CARD');
  };
  const [news, setNews] = useState(null);
  const [newsAccess, setAccess] = useState({});
  const [newsComponents, setNewsComponents] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [loadingNewsCategories, setLoadingNewsCategories] = useState(false);
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [groupsLevelsTiers, setGroupsLevelsTiers] = useState({
    tiers: [],
    groups: [],
    levels: [],
  });
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [newsCategories, setNewsCategories] = useState([]);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] = useState(0);
  const [startDate, setStartDate] = useState();
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] = useState(0);

  const config = useConfig();
  const getNews = async (incommingNewsId) => {
    setLoadingNews(true);
    try {
      const resNews = await Api.news.getNewsById(incommingNewsId || newsID);
      setLoadingNews(false);
      setNews(resNews);
    } catch (err) {
      toast.error(err || p('fetchingNewsFailed'));
      setLoadingNews(false);
    }
  };
  const getNewsAccess = async () => {
    setLoadingAccess(true);
    try {
      const [resAccess, resLevels, resGroups, { data: resTiers }] = await Promise.all([
        Api.news.getNewsAccess(newsID),
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
      ]);
      const mappedTiers = [
        { label: p('allTiers'), value: [] },
        { label: p('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: p('allGroups'), value: [] },
        { label: p('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: p('allLevels'), value: [] },
        { label: p('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
      setAccess(resAccess);
    } catch (err) {
      toast.error(err || p('fetchingNewsFailed'));
      setLoadingAccess(false);
    }
  };
  const getNewsCategories = async () => {
    setLoadingNewsCategories(true);
    try {
      const categoryList = await Api.news.getNewsCategories();
      setNewsCategories(categoryList);
      setLoadingNewsCategories(false);
    } catch (err) {
      setLoadingNewsCategories(false);
      toast.error(p('fetchingNewsFailed'));
    }
  };
  const getGroupsTiersLevels = async () => {
    setLoadingAccess(true);
    try {
      const [resLevels, resGroups, { data: resTiers }] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
      ]);
      const mappedTiers = [
        { label: p('allTiers'), value: [] },
        { label: p('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: p('allGroups'), value: [] },
        { label: p('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: p('allLevels'), value: [] },
        { label: p('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
    } catch (err) {
      toast.error(err || p('fetchingNewsFailed'));
      setLoadingAccess(false);
    }
  };
  useEffect(() => {
    getNewsCategories();
  }, []);
  useEffect(() => {
    if (newsID) {
      getNews();
      getNewsAccess();
    }
    getGroupsTiersLevels();
  }, [newsID]);

  const onNewsSubmit = async (values) => {
    const selectedNewsCategories = values.categories?.map((item) => item.value);
    const payload = {
      title: values.title,
      description: values.description,
      externalLink: values.externalLink,
      categories: selectedNewsCategories || [],
      author: values.author,
      publicationDate: moment(values.publicationDate),
      expirationDate: values.expirationDate ? moment(values.expirationDate) : undefined,
      likesEnabled: values.likesEnabled || false,
      // commentsEnabled: values.commentsEnabled || false,
      commentsEnabled: false,
      isPinned: values.isPinned || false,
      components: [],
    };

    setLoadingNews(true);
    let finalNewsId = null;
    try {
      if (values.imageHeaderType?.value === 'VIDEO') {
        if (values.headingVideo.promise) {
          const publicUrl = await values.headingVideo.promise();
          payload.headingVideo = publicUrl;
        } else {
          payload.headingVideo = values.headingVideo?.preview;
        }
      } else {
        payload.headingImage = values.headingImage.croppedBase64 || values.headingImage.preview;
      }

      if (newsID) {
        payload.components = news.components;
      }

      if (newsID) {
        await Api.news.updateNews(newsID, payload);
      } else {
        const createNewsResponse = await Api.news.createNews(payload);
        finalNewsId = createNewsResponse.id;
      }
      await getNews(newsID || finalNewsId);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    }
    return { newsId: newsID || finalNewsId, isNew: !!finalNewsId };
  };
  const getAccessValues = (searchArray) => {
    if (searchArray.length === 0) {
      return null;
    }
    const foundItem = searchArray.find(item => Array.isArray(item.value) || item.value === null);
    if (foundItem) {
      return foundItem.value;
    }
    return searchArray.map((item) => item.value);
  };
  const onAccessSubmit = async (newsId, values, notShowPopup) => {
    const payload = {
      tiers: getAccessValues(values.tier),
      groups: getAccessValues(values.group),
      levels: getAccessValues(values.level),
      noAccessView: values.noAccessView,
      visible: values.visible,
    };
    setLoadingAccess(true);
    try {
      await Api.news.updateNewsAccess(newsId, payload);
      const accessRes = await Api.news.getNewsAccess(newsId);
      setAccess(accessRes);
      if (!notShowPopup) {
        toast.success(p('newsUpdatedSuccessfully'));
      }
      if (!newsID) {
        history.replace(`/news/edit/${newsId}?tab=2`);
      }
    } catch (err) {
      toast.error(p('updatingAccessFailed'));
    }
    setLoadingAccess(false);
  };
  const onComponentsSubmit = (shouldPublish) => async (incomingData) => {
    try {
      setLoadingComponents(true);
      const { selectedColums, ...components } = incomingData;
      const mappedItems = await componentsFromState(selectedColums.Col1.items, components, true);
      const selectedNewsCategories = news.categories.map((item) => item.id);
      const richTextIndex = mappedItems.findIndex(item => item.type === 'RICH_TEXT');
      if (richTextIndex !== -1) {
        mappedItems[richTextIndex].value.text = mappedItems[richTextIndex].value.text.replace(/<p>/g, '').replace(/<\/p>/g, '</br>');
      }
      const payload = {
        ...news,
        categories: selectedNewsCategories,
        components: mappedItems,
      };
      setLoadingComponents(true);
      await Api.news.updateNews(newsID, payload);
      if (shouldPublish) {
        await onAccessSubmit(newsID, { ...props.accessFormValues, visible: true }, true);
      }
      // await getNews(newsID); This removed the existing data in news form
      toast.success(p('newsUpdatedSuccessfully'));
    } catch (err) {
      toast.error(err?.message || s('somethingWentWrong'));
    }
    setLoadingComponents(false);
  };

  const getScheduleMessagesByServiceId = (async (page = 1, pageSize = 10) => {
    try {
      let messageByEventId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceId(news.id, page, pageSize);
      } else {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(news.id, page, pageSize, messageStatus);
      }
      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total || 0);
      }
      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
    } catch (error) {
      toast.error(error);
    }
  });

  // eslint-disable-next-line consistent-return
  const handleContact = async (values) => {
    const { title, message, type, timeToSend, notificationLabel } = values;
    const scheduledDate = timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null;
    if (!newsAccess.visible) {
      return toast.error(t('ScheduledMessagesPage.newsNotLive'));
    }
    const payload = { title, message, type, tiers: [], levels: [], groups: [], notificationLabel: notificationLabel?.value || null };
    try {
      if (timeToSend === 'SCHEDULED' && scheduleMessageID) {
        await Api.news.updateScheduledMessage(news.id, scheduleMessageID, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.scheduleMessageUpdated')}`);
      } else if (timeToSend === 'INSTANT') {
        await Api.news.contactNews(news.id, payload);
      } else {
        await Api.news.contactNews(news.id, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.messageScheduled')}`);
      }
      if (timeToSend === 'INSTANT') {
        if (type === OfferTypes.CommunicationTypes.push) {
          toast.success(o(`${'pushNotificationSent'}`));
        } else if (type === OfferTypes.CommunicationTypes.email) {
          toast.success(o(`${'emailSent'}`));
        } else {
          toast.success(o(`${'smsSent'}`));
        }
      }
    } catch (_e) {
      toast.error(s(`${'somethingWentWrong'}`));
    }
    getScheduleMessagesByServiceId();
    setScheduleMessageID(null);
  };
  const pollNewsAccess = async (newsId, callBack, iteration = 0) => {
    if (iteration > 10) {
      toast.error(p('updatingAccessFailed'));
      history.replace('/news');
      return;
    }
    try {
      await Api.news.getNewsAccess(newsId);
      callBack();
    } catch (e) {
      setTimeout(() => pollNewsAccess(newsId, callBack, iteration + 1), 1000);
    }
  };
  const handleNewsSubmit = async () => {
    const { newsFormValues, accessFormValues, newsFormValid, accessFormValid } = props;
    if (!newsFormValid) {
      props.dispatch(touch('news_form', 'title', 'publicationDate', 'expirationDate', 'headingImage', 'headingVideo', 'imageHeaderType'));
      return;
    }
    if (!accessFormValid) {
      props.dispatch(touch('access_form', 'level', 'group', 'tier'));
      return;
    }
    setLoadingNews(true);
    const newsData = await onNewsSubmit(newsFormValues);
    if (newsData.isNew) {
      setLoadingAccess(true);
      pollNewsAccess(newsData.newsId, () => onAccessSubmit(newsData.newsId, accessFormValues));
    } else {
      await onAccessSubmit(newsData.newsId, accessFormValues);
    }
    setLoadingNews(false);
  };
  const saveButtonText = props?.accessFormValues?.visible ? p('publish') : s('save');
  return (
    <Container>
      <Loading loading={loadingNews || loadingAccess || loadingComponents || loadingNewsCategories} />
      <Row>
        <Col lg="7">
          <Card>
            <CardBody style={{ padding: 0, borderRadius: 6 }}>
              <NavTab
                tabs={[
                  { tab: s('details') },
                  { tab: s('content'), disabled: !newsID || !['sportalityAPI', 'custom'].includes(news?.type) },
                  {
                    tab: (
                      <>
                        {s('communication')}
                        {numberOfPendingMessagesPerEvent > 0 && <Badge style={{ marginLeft: 5 }} color="primary">{numberOfPendingMessagesPerEvent}</Badge>}
                      </>
                    ),
                    disabled: !newsID,
                  },
                  { tab: p('engagement'), disabled: !newsID || !['sportalityAPI', 'custom'].includes(news?.type) || !props?.newsFormValues?.likesEnabled },
                ]}
                style={{ marginBottom: 0 }}
                activeTab={activeTab}
                setTab={handleTabChange}
                size="sm"
              />
              <div className="tabs tabs--justify tabs--bordered-top overflow-unset">
                <div className="tabs__wrap">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <NewsForm
                        news={news}
                        newsID={newsID}
                        categories={newsCategories}
                      />
                      <AccessForm
                        news={news}
                        access={newsAccess}
                        groupsLevelsTiers={groupsLevelsTiers}
                      />
                      <Col className="mt-3">
                        <Button color="primary" onClick={handleNewsSubmit}>
                          {saveButtonText}
                        </Button>
                        <Link
                          className="color-unset"
                          to="/news"
                        >
                          <Button type="button">
                            {s('close')}
                          </Button>
                        </Link>
                      </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <NewsComponentsForm
                        news={news}
                        components={news?.components || []}
                        onSubmitPublish={onComponentsSubmit(true)}
                        onSubmitSave={onComponentsSubmit(false)}
                        newsObjects={(data) => {
                          const o1 = JSON.stringify(data.Col1.items);
                          const o2 = JSON.stringify(newsComponents);
                          if (data?.Col1?.items.length !== 0 && o1 !== o2) {
                            setNewsComponents(data.Col1.items);
                          }
                        }}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <NewsContactForm
                        onSubmit={handleContact}
                        news={news}
                        access={newsAccess}
                        scheduleMessageID={scheduleMessageID}
                        setScheduleMessageID={setScheduleMessageID}
                        scheduleMessagesForEvent={scheduleMessagesForEvent}
                        getScheduleMessagesByServiceId={getScheduleMessagesByServiceId}
                        setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                        totalAmountOfScheduledMessages={totalAmountOfScheduledMessages}
                        messageStatus={messageStatus}
                        setMessageStatus={setMessageStatus}
                        tiers={groupsLevelsTiers.tiers}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <LikesForm
                        newsID={newsID}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="5">
          <Card>
            <CardBody className="p-0">
              <CardTitle style={{ padding: '11px 11px 7px', marginBottom: 22, fontWeight: 600 }} className="border-bottom"><span style={{ fontSize: 16 }}>{t('NewsForm.appPreview')}</span>
                <div style={{ float: 'right', marginTop: -10 }}>
                  <button href="#" className={`btn-tab ${previewType === 'CARD' ? 'active' : ''}`} onClick={() => { setPreviewType('CARD'); }} ><CardVariantIcon className="icon" /> {s('tile')}</button>
                  <button href="#" className={`btn-tab ${previewType === 'POST' ? 'active' : ''} ${!newsID ? 'disabled' : ''}`} onClick={() => { setPreviewType(!newsID ? 'CARD' : 'POST'); }}><BookOpenVariantIcon className="icon" /> {s('content')}</button>
                </div>
              </CardTitle>
              {previewType === 'CARD' &&
                <div>
                  <div style={{ borderRadius: 6, margin: '10px auto', width: 325, height: 64, background: 'linear-gradient(0deg, rgba(232,234,237,1) 0%, rgba(255,255,255,1) 100%)' }} />
                  <AppNewsCardPreview style={{ margin: '0 auto 10px' }} data={props?.newsFormValues} />
                  <div style={{ borderRadius: 6, margin: '0 auto 10px', width: 325, height: 64, background: 'linear-gradient(180deg, rgba(232,234,237,1) 0%, rgba(255,255,255,1) 100%)' }} />
                </div>
              }
              {previewType === 'POST' &&
                <AppNewsPagePreview news={news} selectedItems={newsComponents} />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  accessFormValues: getFormValues('access_form')(state),
  newsFormValues: getFormValues('news_form')(state),
  accessFormValid: isValid('access_form')(state),
  newsFormValid: isValid('news_form')(state),
});
export default connect(mapStateToProps)(NewsFormPage);
