import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import axios from 'axios';
import { getImageHash } from '../../../util/functions';
import t from '../../../util/translation/translation';

export const parseField = async (fieldId, value, order, isSubmit = false) => {
  const fieldBase = fieldId.split('-')[0];
  switch (fieldBase) {
    case 'IMAGE':
      return {
        type: 'IMAGE',
        order,
        value: {
          image: value.croppedBase64 || value.preview,
          author: value.author,
          link: value.link,
        },
      };
    case 'BUTTON':
      return {
        type: 'BUTTON',
        order,
        value: {
          text: value.text,
          link: value.link,
        },
      };
    case 'RICH_TEXT': {
      const cont = draftToHtml(convertToRaw(value.getCurrentContent()));
      return {
        type: 'RICH_TEXT',
        order,
        value: {
          text: cont,
        },
      };
    }
    case 'RICH_TEXT2': {
      const cont = value;
      return {
        type: 'RICH_TEXT2',
        order,
        value: {
          text: cont,
        },
      };
    }
    case 'IMAGE_PHOTOGRAPHER': {
      return {
        type: 'IMAGE_PHOTOGRAPHER',
        order,
        value: {
          text: value,
        },
      };
    }
    case 'I_FRAME': {
      return {
        type: 'I_FRAME',
        order,
        value: {
          iframe: value,
        },
      };
    }
    case 'YOUTUBE_VIDEO': {
      if (isSubmit) {
        try {
          await axios.get(`https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${value}&format=json`);
        } catch (e) {
          throw new Error(t('NewsForm.youTubeError'));
        }
      }
      return {
        type: 'YOUTUBE_VIDEO',
        order,
        value: {
          video: value,
        },
      };
    }
    case 'SPORTALITY_VIDEO': {
      return {
        type: 'SPORTALITY_VIDEO',
        order,
        value: {
          video: value,
        },
      };
    }
    case 'HEADER': {
      return {
        type: 'HEADER',
        order,
        value: {
          text: value,
        },
      };
    }
    case 'DELIMITER': {
      return {
        type: 'DELIMITER',
        order,
        value: {
          text: value,
        },
      };
    }
    case 'CUSTOM_VIDEO': {
      if (isSubmit) {
        if (value.promise) {
          const publicUrl = await value.promise();
          return {
            type: 'CUSTOM_VIDEO',
            order,
            value: {
              video: publicUrl,
            },
          };
        } else {
          return {
            type: 'CUSTOM_VIDEO',
            order,
            value: {
              video: value?.preview || value,
            },
          };
        }
      } else {
        return {
          type: 'CUSTOM_VIDEO',
          order,
          value: {
            video: value,
          },
        };
      }
    }
    default:
      return null;
  }
};
export const componentsFromState = async (selectedItems, componentValues, isSubmit = false) => {
  const mappedItemsPromises = selectedItems
    .filter((item) => !!componentValues[item.id] || item.name.includes('delimiter') || item.id.toLowerCase().includes('image') || item.id.toLowerCase().includes('button'))
    .map((selectedItem, idx) => {
      if (selectedItem.id.toLowerCase().includes('image')) {
        const imageItem = componentValues[`${selectedItem.id}_image`];
        const authorItem = componentValues[`${selectedItem.id}_author`];
        const linkItem = componentValues[`${selectedItem.id}_link`];
        return parseField(selectedItem.id, { ...imageItem, author: authorItem || '', link: linkItem || '' }, idx);
      } else if (selectedItem.id.toLowerCase().includes('button')) {
        const textItem = componentValues[`${selectedItem.id}_text`];
        const linkItem = componentValues[`${selectedItem.id}_link`];
        return parseField(selectedItem.id, { text: textItem || '', link: linkItem || '' }, idx);
      } else {
        const itemValue = componentValues[selectedItem.id];
        return parseField(selectedItem.id, itemValue, idx, isSubmit);
      }
    });
  const mappedItems = await Promise.all(mappedItemsPromises);
  return mappedItems;
};
export const AVAILABLE_COMPONENTS = [
  { name: 'richText2', id: 'RICH_TEXT2' },
  { name: 'richText', id: 'RICH_TEXT' },
  { name: 'image', id: 'IMAGE' },
  // { name: 'imagePhotographer', id: 'IMAGE_PHOTOGRAPHER' },
  { name: 'header', id: 'HEADER' },
  { name: 'delimiter', id: 'DELIMITER' },
  { name: 'youtubeVideo', id: 'YOUTUBE_VIDEO' },
  { name: 'sportalityVideo', id: 'SPORTALITY_VIDEO' },
  { name: 'customVideo', id: 'CUSTOM_VIDEO' },
  { name: 'iFrame', id: 'I_FRAME' },
  { name: 'button', id: 'BUTTON' },
];
const getInitialComponentState = (fieldId, value, variableName) => {
  const fieldBase = fieldId.split('-')[0];
  switch (fieldBase) {
    case 'IMAGE':
      if (variableName === 'link') {
        return value.link;
      } else if (variableName === 'author') {
        return value.author;
      } else {
        return {
          name: 'test name',
          preview: value.image,
          croppedImage: value.image,
          hash: getImageHash(value.image),
        };
      }
    case 'BUTTON':
      if (variableName === 'link') {
        return value.link;
      } else if (variableName === 'text') {
        return value.text;
      } else {
        return value.text;
      }
    case 'RICH_TEXT': {
      const blocksFromHTML = convertFromHTML(value.text);
      const htmlstate = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return EditorState.createWithContent(htmlstate);
    }
    case 'RICH_TEXT2': {
      return value.text;
    }
    case 'IMAGE_PHOTOGRAPHER':
      return value.text;
    case 'YOUTUBE_VIDEO':
      return value.video;
    case 'SPORTALITY_VIDEO':
      return value.video;
    case 'HEADER':
      return value.text;
    case 'DELIMITER':
      return value.text;
    case 'I_FRAME':
      return value.iframe;
    case 'CUSTOM_VIDEO':
      return {
        preview: value.video,
      };
    default:
      return null;
  }
};
export const stateFromComponents = (components) => {
  const preparedComponents = components
    .filter((item) => item.type !== 'IMAGE_PHOTOGRAPHER')
    .sort((a, b) => a.order - b.order)
    .map((item, idx) => {
      const found = AVAILABLE_COMPONENTS.find((avb) => item.type === avb.id);
      return {
        name: found.name,
        id: `${found.id}-${idx}`,
        value: item.value,
      };
    });
  const constructedObject = {};
  for (let i = 0; i < preparedComponents.length; i++) {
    const comp = preparedComponents[i];
    if (comp.id.toLowerCase().includes('image')) {
      constructedObject[`${comp.id}_image`] = getInitialComponentState(comp.id, comp.value, false);
      constructedObject[`${comp.id}_author`] = getInitialComponentState(comp.id, comp.value, 'author');
      constructedObject[`${comp.id}_link`] = getInitialComponentState(comp.id, comp.value, 'link');
    } else if (comp.id.toLowerCase().includes('button')) {
      constructedObject[`${comp.id}_text`] = getInitialComponentState(comp.id, comp.value, 'text');
      constructedObject[`${comp.id}_link`] = getInitialComponentState(comp.id, comp.value, 'link');
    } else {
      constructedObject[comp.id] = getInitialComponentState(comp.id, comp.value);
    }
  }
  return {
    state: constructedObject,
    items: preparedComponents.map((item) => ({
      name: item.name,
      id: item.id,
    })),
  };
};
export const compareComponents = (oldComponents, newComponents) => {
  if (newComponents.length !== oldComponents.length) {
    return true;
  }
  const componentsDifferent = oldComponents.some((oldComp, index) => {
    const newComp = newComponents[index];
    if (JSON.stringify(oldComp.value) !== JSON.stringify(newComp.value)) {
      return true;
    }
    return false;
  });
  return componentsDifferent;
};
