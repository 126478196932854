import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { useLocation, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { toast } from 'react-toastify';
import RenderSelectField from '../../../shared/components/form/Select';
import renderField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import useModal from '../../../shared/hooks/useModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import validateMatchContact from './validationMatchContactForm';

const MatchContactForm = ({
  handleSubmit,
  initialize,
  formValues,
  matchID,
  scheduleMessageID,
  setScheduleMessageID,
  scheduleMessagesForMatch,
  getScheduleMessagesByServiceId,
  clearContactPage,
  setClearContactPage,
  startDate,
  setStartDate,
  setLoading,
}) => {
  const p = partial('EventsContactForm');
  const mf = partial('MessagesForm');
  const m = partial('MessagesForm');
  const s = partial('shared');

  const { state } = useLocation();
  const [handleModal, visible, deleteId] = useModal();
  const [notificationLabels, setNotificationLabels] = useState([]);
  const screens = [
    {
      label: s('matchEvents'),
      value: `/more/matchCenterMatch/${matchID}`,
    },
    {
      label: s('lineup'),
      value: `/more/matchCenterMatch/${matchID}/lineUp`,
    },
    {
      label: s('MVP'),
      value: `/more/matchCenterMatch/${matchID}/ff`,
    },
  ];

  const initializeEdit = (message) => {
    const landingScreen = screens.find(screen => screen.value === message?.payload?.placeholders?.url);
    setScheduleMessageID(message.id);
    if (formValues) {
      initialize({
        title:
          message?.payload?.placeholders?.title ||
          message?.payload?.placeholders?.SUBJECT,
        content: message?.payload?.placeholders?.body,
        screen: landingScreen,
        scheduleTime: message.date
          ? setStartDate(new Date(message.date))
          : null,
        timeToSend: 'SCHEDULED',
      });
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const clearFields = () => {
    setClearContactPage(false);
    setScheduleMessageID(null);
    initialize({
      title: '',
      content: '',
      screen: screens[0],
      scheduleTime: setStartDate(''),
      timeToSend: 'INSTANT',
    });
  };

  const removeMessage = async (id) => {
    setLoading(true);
    try {
      await Api.scheduleMessages.deleteMessage(id);
      handleModal();
      getScheduleMessagesByServiceId();
    } catch (err) {
      setLoading(false);
      toast.error(err || 'errorRemovingMessage');
    }
  };

  // const badgeColor = (messageState) => {
  //   switch (messageState) {
  //     case 'Sent':
  //       return '#70BBFD';
  //     case 'Scheduled':
  //       return '#FF9900';
  //     default:
  //       return '#000000';
  //   }
  // };

  useEffect(() => {
    clearFields();
  }, [clearContactPage]);

  useEffect(() => {
    const fetchNotificationLabels = async () => {
      const res = await Api.userDevices.getAllNotificationLabels();
      setNotificationLabels(res.map((label) => ({
        value: label.id,
        label: label.title,
      })));
    };
    fetchNotificationLabels();
  }, []);

  useEffect(() => {
    if (state?.messageId && scheduleMessagesForMatch.length > 0) {
      const messageToShow = scheduleMessagesForMatch.find(
        (msg) => msg.id === state.messageId,
      );
      initializeEdit(messageToShow);
    }
  }, [scheduleMessagesForMatch]);

  const renderSubmitButtons = () => {
    let buttonName = '';
    if (scheduleMessageID) {
      buttonName = t('ScheduledMessagesPage.updateMessage');
    } else if (formValues?.timeToSend === 'SCHEDULED') {
      buttonName = t('ScheduledMessagesPage.scheduleMessage');
    } else {
      buttonName = t('shared.sendPushNotification');
    }

    return (
      <div>
        <Button type="submit" style={{ display: 'block' }} color="primary">
          {buttonName}
        </Button>
        <Link className="py-3" style={{ textDecoration: 'underline' }} to="/messages?tab=2">{t('MessagesForm.scheduledAndHistory')}</Link>
      </div>
    );
  };

  const filterPassedTime = (time) => {
    const isSameDay = moment().diff(moment(time), 'days') === 0;
      if (isSameDay) {
        return moment(time).isSameOrAfter(moment());
      }
      return true;
  };

  return (
    <form onSubmit={handleSubmit} className="form d-flex">
      <Container className="flex">
        <DeleteModal
          visible={visible}
          handleModal={handleModal}
          type="message"
          modalAction={() => removeMessage(deleteId)}
        />
        <div className="flex-1 marginRight-15">
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.title')}</span>
                {formValues?.type === 'sms' ? (
                  <Field
                    name="title"
                    placeholder={`${t('shared.title')} (${t(
                      'EventsContactForm.appNameIfEmpty',
                    )})`}
                    component={renderField}
                    disabled
                  />
                ) : (
                  <div
                    className="form__form-group-field"
                    style={{ margin: '-5px -10px' }}
                  >
                    <Field
                      name="title"
                      placeholder={`${t('shared.title')} (${t(
                        'EventsContactForm.appNameIfEmpty',
                      )})`}
                      component={renderField}
                      emoji
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.content')}</span>
                <div
                  className="form__form-group-field"
                  style={{ margin: '-5px -10px' }}
                >
                  <Field
                    name="content"
                    placeholder={p('messageContent')}
                    component={renderTextAreaField}
                    emoji
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Row > */}
            <Col md={11}>
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  {s('landing')}
                </span>
                <div className="form__form-group-field d-flex marginRight-15">
                  <Field
                    // disabled={!selectedScreen || selectedScreen.values?.length === 0 || smsForm.media === 'sms' || selectedScreen.values === undefined}
                    name="screen"
                    component={RenderSelectField}
                    placeholder={mf('chooseScreen')}
                    options={screens ?? []}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('shared.notificationLabel')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="notificationLabel"
                    component={RenderSelectField}
                    placeholder={t('MessagesForm.chooseNotificationLabel')}
                    options={notificationLabels}
                  />
                </div>
              </div>
            </Col>
            {/* </Row> */}
            <Col md={12}>
              <span className="form-header">
                {t('ScheduledMessagesPage.timeToSend')}
              </span>
              <div className="radio-horizontal">
                <Col md={6}>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.instant')}
                        radioValue="INSTANT"
                        defaultChecked
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.scheduled')}
                        radioValue="SCHEDULED"
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={7}>
              {formValues.timeToSend === 'SCHEDULED' && (
                <div className="form__form-group" style={{ zIndex: 100 }}>
                  <span className="form-header">
                    {t('ScheduledMessagesPage.scheduleTime')}
                  </span>
                  <div className="form__form-group-field">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="pt-BR"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      placeholderText="DD / MM / YYYY  -- : --"
                      disabledKeyboardNavigation
                    />
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="flex-1 marginLeft-8">
          <span>&nbsp;</span>
          <div className="flex align-items-center p-3 border">
            <div className="modal-icon m-2">
              <AlertCircleOutlineIcon size="38" />
            </div>

            <div>
              <p>{p('pushMessageGroups')}</p>
              <p>{m('AppStorePushGuidelines')}</p>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        {renderSubmitButtons()}
        {scheduleMessageID && (
          <Button onClick={clearFields}>{t('shared.clear')}</Button>
        )}
      </Container>
    </form>
  );
};


MatchContactForm.defaultProps = {
  event: null,
  formValues: {},
};

const reduxF = reduxForm({
  form: 'MatchContactForm',
  validate: validateMatchContact,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('MatchContactForm')(state),
});

export default reduxF(connect(mapStateToProps)(MatchContactForm));

