import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import { uploadVideo } from '../../../util/functions';

const p = partial('dropZoneShare');

class DropZoneVideo extends PureComponent {
  static defaultProps = {
    className: '',
    customHeight: false,
    meta: null,
  };


  componentWillUnmount() {
    const selectedVideo = this.getVideo();
    if (selectedVideo?.preview) {
      URL.revokeObjectURL(selectedVideo.preview);
    }
  }
  async handleVideoChange(originalFile) {
    const previewUrl = URL.createObjectURL(originalFile);
    const videos = uploadVideo(originalFile);
    const payload = {
      promise: videos,
      ...originalFile,
      src: previewUrl,
      preview: previewUrl,
      value: originalFile.name,
      file: originalFile,
    };
    this.props.onChange(payload);
  }
  handleRemoveVideo(e) {
    e.preventDefault();
    this.props.onChange({
      remove: Api.images.removeImage,
      hash: this.props?.value?.hash,
    });
  }
  getVideo() {
    const selectedVideo = Array.isArray(this.props.value) ? this.props.value[0] : this.props.value;
    if (selectedVideo && !selectedVideo?.remove) {
      const video = { ...selectedVideo, preview: selectedVideo.preview };
      return video;
    }
    return null;
  }
  render() {
    const selectedVideo = this.getVideo();
    const { touched, error } = this.props.meta || {};
    return (
      <div className="dropzone--container">
        <div className={`dropzone dropzone--single${this.props.customHeight ? ' dropzone--custom-height' : ''} ${this.props.className}`}>
          <Dropzone
            className="dropzone__input"
            accept="video/mp4, video/mov, video/ogg, video/mpeg"
            name={this.props.name}
            multiple={false}
            onDrop={async ([original]) => {
              await this.handleVideoChange(original);
            }}
          >
            {!selectedVideo ? (
              <div className="dropzone__drop-here">
                <img height="40" src="/assets/icons/upload.svg" alt="upload" />
                <span className="lnr lnr-upload">{p('dropFilesToUpload')}</span>
              </div>
            ) : (
              <video
                controls
                style={{ width: '100%', height: 'auto', maxHeight: '1000px', objectFit: 'contain' }}
                src={selectedVideo.preview}
              >
                Your browser does not support the video tag.
              </video>
            )}
          </Dropzone>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
          <small>{t('NewsPage.imageUploadDisclaimer')}</small>
          {selectedVideo && (
            <div style={{ marginTop: 4 }}>
              <Button
                size="sm"
                color="danger"
                className="mb-0 mr-1"
                outline
                onClick={(e) => this.handleRemoveVideo(e)}
              >
                {p('remove')}
              </Button>
            </div>
          )}
        </div>
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  }
}

const renderDropZoneVideo = props => (
  <DropZoneVideo
    {...props.input}
    meta={props.meta}
    className={props.className}
    customHeight={props.customHeight}
  />
);

renderDropZoneVideo.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  customHeight: PropTypes.bool,
  className: PropTypes.string,
};

renderDropZoneVideo.defaultProps = {
  meta: null,
  customHeight: false,
  className: '',
};

export default renderDropZoneVideo;
