import React, { useEffect } from 'react';
import { Card, CardBody, Button, Col, Container, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const s = partial('shared');
const p = partial('TeamProfileForm');
const TeamProfileForm = ({ handleSubmit, initialize, team, setOpen }) => {
  useEffect(() => {
    if (team) {
      initialize(team);
      console.log(team);
    }
  }, [team]);
  return (
    <Card>
      <CardBody className="pb-1">
        <form onSubmit={handleSubmit} className="form">
          <Container>
            <Col>
              <Label htmlFor="name">{s('name')}</Label>
              <Field
                name="name"
                component={renderField}
                type="text"
              />
            </Col>
            <Col>
              <Label htmlFor="shortName">{p('shortName')}</Label>
              <Field
                name="shortName"
                component={renderField}
                type="text"
              />
            </Col>
            <Col>
              <Label htmlFor="matchCenterUrl">{p('matchCenterUrl')}</Label>
              <Field
                name="matchCenterUrl"
                component={renderField}
                type="text"
              />
            </Col>
            <Col>
              <Label htmlFor="standingsUrl">{p('standingsUrl')}</Label>
              <Field
                name="standingsUrl"
                component={renderField}
                type="text"
              />
            </Col>
            <Col>
              <Label htmlFor="playerStatsUrl">{p('playerStatsUrl')}</Label>
              <Field
                name="playerStatsUrl"
                component={renderField}
                type="text"
              />
            </Col>
            <Col>
              <Label htmlFor="appVisibility">{t('SponsorForm.playerCard')}</Label>
              <Field
                name="appVisibility"
                component={renderCheckBoxField}
                // label="Hide"
                className="colored-click"
                label={s('show')}
              />
            </Col>
            <Col>
              <Label htmlFor="matchCenterVisibility">{t('AppLayout.matchcenterTile')}</Label>
            </Col>
            <Col>
              <div className="form__form-group">
                <Field
                  name="matchCenterVisibility"
                  component={renderCheckBoxField}
                  className="colored-click"
                  label={s('show')}
                />
              </div>
            </Col>
            <Row>
              <Button color="primary" size="sm" type="submit">{s('edit')}</Button>
              <Button size="sm" color="secondary" onClick={() => setOpen(false)}>{s('close')}</Button>
            </Row>
          </Container>
        </form>
      </CardBody>
    </Card>
  );
};

const rf = reduxForm({
  form: 'teamProfileForm',
});

export default rf(TeamProfileForm);
