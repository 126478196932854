import React, { useCallback, useEffect } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { Field, reduxForm, getFormValues, propTypes as reduxFormProps, FieldArray } from 'redux-form';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import { connect } from 'react-redux';
import DeleteIcon from '../../events/components/QuestionnaireBuilder/icons/DeleteIcon';
import renderField from '../../../shared/components/custom/Field';
import validatePlayerForm from './validatePlayerForm';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderSelectField from '../../../shared/components/form/Select';
import renderSwitchField from '../../../shared/components/custom/Switch';
import userLanguageState from '../../../recoil/userLanguage/selector';
import { partial } from '../../../util/translation/translation';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import { getImageHash } from '../../../util/functions';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';


countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

const PlayerForm = ({
  handleSubmit, player, initialize, sponsors, teamPlayers, formValues }) => {
  const userLanguage = useRecoilValue(userLanguageState);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(([iso, country]) => ({
    name: country,
    iso,
    value: iso,
    label: country,
  }));
  countryOptions.push(
    { name: 'England', iso: 'GB-ENG', value: 'GB-ENG', label: 'England' },
    { name: 'Scotland', iso: 'GB-SCT', value: 'GB-SCT', label: 'Scotland' },
    { name: 'Wales', iso: 'GB-WLS', value: 'GB-WLS', label: 'Wales' },
  );
  const s = partial('shared');
  const p = partial('PlayerForm');
  useEffect(() => {
    if (player && sponsors) {
      const selectedCountry = countryOptions?.find(country => country?.iso === player?.nationality);
      const sponsor = sponsors.find(_sponsor => _sponsor.id === player.companyId);
      initialize({
        firstname: player.firstname,
        lastname: player.lastname,
        position: player.position,
        infoUrl: player.infoUrl,
        jerseyNumber: player.jerseyNumber,
        jerseyName: player.jerseyName,
        nationality: selectedCountry || null,
        dateOfBirth: player.dateOfBirth ? moment(player.dateOfBirth).format('YYYY-MM-DD') : undefined,
		imageUrl: player.imageUrl ? {
			name: 'imageUrl',
			preview: player.imageUrl,
			hash: getImageHash(player.imageUrl),
      crop: player.imageCrop,
		  } : null,
        height: player.height,
        weight: player.weight,
        shots: player.shots,
        company: sponsor ? { label: sponsor.name, value: sponsor.id } : undefined,
        active: !!player.active,
        crop: player.imageCrop,
        bio: player.bio,
        playerStats: player?.playerStats?.map(stat => ({ label: stat.label, value: stat.value })) || [],
      });
    }
  }, [player]);

  const renderStats = useCallback(({ fields }) => (
    <>
      <div className={fields.length > 0 ? 'question-answers-list flex-column' : ''}>
        {fields.map((input, index) => (
          <div key={index.toString()} className="picked-question-card">
            <div className="content-area">
              <div className="gx-0 row">
                <div className="question-content col">
                  <Field
                    name={`${input}.label`}
                    placeholder={s('title')}
                    component={renderField}
                  />
                </div>
                <div className="question-content col">
                  <Field
                    name={`${input}.value`}
                    placeholder={`${s('number')} (max 100)`}
                    component={renderField}
                  />
                </div>
                <div className="col-3 d-flex justify-content-end col">
                  <div className="card-actions">
                    <button
                      className="action-button red-border"
                      onClick={() => fields.remove(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button type="button" style={{ background: 'transparent', border: 'none', color: '#70bbfd' }} onClick={() => fields.push({})}>
        + {s('add')}
      </button>
    </>
  ), []);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col xl={8} lg={7} md={6}>
        {player?.externalProviderId.startsWith('00') && teamPlayers.filter((_p) => !_p.externalProviderId.startsWith('00')).length > 0 && (
          <Row>
            <Col lg={4} md={12}>
              <div className="form__form-group">
                <span className="form-header">{p('mergePlayer')} <TooltipWithIcon text={p('mergePlayerToolTip')} id={`tt${player.id}`} /></span>
                <Field
                  name="mergePlayer"
                  component={renderSelectField}
                  options={teamPlayers.filter(_p => !_p.externalProviderId.startsWith('00')).map((_p) => ({
                  label: _p.fullname,
                  value: `${_p.id}`,
                }))}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('firstname')}</span>
              <Field name="firstname" component={renderField} placeholder={s('firstname')} />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('lastname')}</span>
              <Field
                name="lastname"
                component={renderField}
                placeholder={s('lastname')}
              />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="form__form-group">
              <span className="form-header">Jersey name</span>
              <Field
                name="jerseyName"
                component={renderField}
                placeholder="JerseyName"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={6}>
            <div className="form__form-group">
              <span className="form-header">{s('shirtNumber')}</span>
              <Field
                name="jerseyNumber"
                component={renderField}
                placeholder={s('shirtNumber')}
              />
            </div>
          </Col>
          <Col xl={3} lg={6}>
            <div className="form__form-group">
              <span className="form-header">{s('position')}</span>
              <Field
                name="position"
                component={renderField}
                placeholder={s('position')}
              />
            </div>
          </Col>
          <Col xl={6} lg={12}>
            <div className="form__form-group">
              <span className="form-header">{s('sponsor')}</span>
              <Field
                name="company"
                component={renderSelectField}
                placeholder={s('chooseSponsor')}
                options={sponsors}
                isClearable
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12}>
            <div className="form__form-group">
              <span className="form-header">{p('infoUrl')}</span>
              <TooltipWithIcon text={p('infoUrlInfo')} id="playerinfotooltip"/>
              <Field
                name="infoUrl"
                component={renderField}
                placeholder={`https://www.playerstats.com/${player?.firstname || 'player'}_${player?.lastname || '#01'}`}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={3} md={6} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{s('height')}</span>
              <Field
                name="height"
                type="number"
                component={renderField}
                placeholder={s('height')}
              />
            </div>
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{s('weight')}</span>
              <Field
                name="weight"
                type="number"
                component={renderField}
                placeholder={s('weight')}
              />
            </div>
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <div className="form__form-group">
              <span className="form-header">{s('shoots')}</span>
              <div className="flex">
                <Field
                  name="shots"
                  component={renderRadioButtonField}
                  radioValue="L"
                  label={s('left')}
                  defaultChecked
                />
                <Field
                  name="shots"
                  component={renderRadioButtonField}
                  radioValue="R"
                  label={s('right')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('nationality')}</span>
              <Field
                name="nationality"
                component={renderSelectField}
                options={countryOptions}
                placeholder={s('chooseCountry')}
              />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('dateOfBirth')}</span>
              <Field
                name="dateOfBirth"
                component={renderField}
                type="date"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('bio')}</span>
              <Field
                header={[2, 3, false]}
                name="bio"
                placeholder="Bio"
                component={TextEditor2}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 10 }} title="Add stats">
          <Col md={12}>
            <div className="form__form-group">
              <span className="form-header">{s('playerStats')} (Spider)</span>
              <div>
                <FieldArray name="playerStats" component={renderStats} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group flex-Vcenter">
              <Field
                name="active"
                defaultChecked
                label={[s('active'), s('inActive')]}
                component={renderSwitchField}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button color="primary">{player ? s('save') : s('create')}</Button>
            <Link to="/players"><Button>{s('close')}</Button></Link>
          </Col>
        </Row>
      </Col>
      <Col xl={4} lg={5} md={6}>
        <Row>
          <div className="form__form-group">
            <span className="form-header">{p('previewPlayerCard')}</span>
            <Field name="imageUrl" maxSize={3} component={renderDropZoneField} crop="PLAYER_IMAGE_CROP" />
            <>
              <p><strong>{s('note')}:</strong> {p('playerPictureInformation')}</p>
              <a href="/assets/files/PlayerCardTemplate.psd" target="_blank" download>{p('downloadPlayerTemplate')}</a>
            </>
            <p className="form-header">{p('previewPlayerProfileImage')}</p>
            {formValues.imageUrl && !formValues.imageUrl?.remove && <img className="player-card-logo" src={formValues.imageUrl.croppedImage || formValues.imageUrl?.preview} alt="" />}
          </div>
        </Row>
      </Col>
    </form>
  );
};

PlayerForm.propTypes = {
  ...reduxFormProps,
  sponsors: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  player: PropTypes.shape({
    externalProviderId: PropTypes.string,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    position: PropTypes.string,
    jerseyNumber: PropTypes.string,
    nationality: PropTypes.string,
    dateOfBirth: PropTypes.string,
    infoUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    imageCrop: PropTypes.shape(),
    height: PropTypes.number,
    weight: PropTypes.number,
    shots: PropTypes.string,
    jerseyName: PropTypes.string,
  }),
  formValues: PropTypes.shape({}),
};

PlayerForm.defaultProps = {
  player: null,
  formValues: {},
};

const reduxF = reduxForm({
  form: 'player_form',
  validate: validatePlayerForm,
});
const mapStateToProps = (state) => ({
	formValues: getFormValues('player_form')(state),
  });
export default connect(mapStateToProps)(reduxF(PlayerForm));


