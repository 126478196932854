import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import userLanguageState from '../../recoil/userLanguage/selector';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import AddEmployee from './components/AddEmployee';
import t, { partial } from '../../util/translation/translation';
import BusinessInfoForm from './components/BusinessInfoForm';
import useConfig from '../../util/useConfig';
import AlertModal, { AlertModalType } from '../../shared/components/custom/AlertModal';
import SponsorForm from './components/SponsorForm';
import NavTab from '../../shared/components/custom/NavTab';
import CustomEventCategoryCard from './components/CustomEventCategoryPage';

const s = partial('shared');
const p = partial('CompanyFormPage');
const a = partial('AlertModal');

const CompanyFormPage = () => {
  const [lang] = useRecoilState(userLanguageState);
  const { companyID } = useParams();
  const config = useConfig();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [company, setCompany] = useState({});
  const [categories, setCategories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [levels, setLevels] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccesModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [createdCompanyId, setCreatedCompanyId] = useState('');
  const [existingCompanyFound, setExistingCompanyFound] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setselectedCompany] = useState();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const defaultActiveTab = new URLSearchParams(location.search).get('tab') || 1;
    setActiveTab(defaultActiveTab || '1');
  }, [location.search]);

  const getCompanyInformation = async () => {
    setLoading(true);
    try {
      if (companyID || selectedCompany) {
        const resCompany = await Api.companies.getCompany(companyID || selectedCompany);
        setLoading(false);
        if (resCompany) {
          setCompany(resCompany);
        } else {
          toast.error(`${p('companyInfoFetchingERROR')}'!'`);
          setCompany({});
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('companyInfoFetchingERROR')}'!'`);
      setCompany({});
    }
  };

  const handleExistingCompanySelect = (resCompany) => {
    setCompany(resCompany);
    setExistingCompanyFound(true);
  };

  const getPageHeading = (pageNo) => {
    switch (pageNo) {
      case 1:
        return `${`${companyID ? s('edit') : s('create')} ${s('companySmall')}`}`;
      case 2:
        return `${p('addContactPerson')}`;
      default:
        return '';
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const resCategories = await Api.companies.getCompanyCategories();
      if (resCategories && resCategories.data) {
        setCategories(resCategories.data);
      } else {
        toast.error(`${p('categoryFetchingERROR')}'!'`);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('categoryFetchingERROR')}'!'`);
    }
  };

  const getEmployees = async () => {
    setLoading(true);
    try {
      const companyId = companyID || createdCompanyId;
      if (companyId) {
        const resEmployees = await Api.employees.getEmployees(companyId);
        const formatedEmployees = resEmployees && resEmployees.data.length > 0 ? resEmployees.data : [];
        const formatEmp = formatedEmployees.map((emp) => {
         return {
          ...emp,
          isPrimaryContact: emp.status === 'PRIMARY_CONTACT' || emp.status === 'PRIMARY_AND_VIP',
          isVIP: emp.status === 'VIP' || emp.status === 'PRIMARY_AND_VIP',
         };
        });
        setEmployees(formatEmp);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('companyEmployeeFetchingERROR')}'!'`);
    }
  };

  const getCompanies = (type) => async (search) => {
    try {
      const resCompanies = await Api.companies.getCompanyByField(search, 'organization_number');
      const companyOptionsMap = resCompanies.map((item) => ({
        label: `${item.generalName} ${item.organization_number}`,
        value: item.id,
      }));
      setCompanyOptions(companyOptionsMap);
    } catch (err) {
      if (type !== 'edit') {
        toast.info(p('companyNotFound'));
      }
    }
  };
  const getLevels = async () => {
    try {
      const resLevels = await Api.club.getLevels();
      const formattedLevels = resLevels.map(({ value }) => {
        return {
          name: value,
          value,
          label: value,
        };
      });
      formattedLevels.push({ name: 'None', value: null, label: 'None' });
      setLevels(formattedLevels);
    } catch (err) {
      toast.error(`${p('levelsFetchingError')}'!'`);
    }
  };

  useEffect(() => {
    if (companyID || selectedCompany) getCompanyInformation();
  }, [companyID, selectedCompany]);

  useEffect(() => {
    getEmployees();
  }, [createdCompanyId, companyID]);

  useEffect(() => {
    getCategories();
    getLevels();
  }, []);

  const nextPage = () => {
    setActiveTab((parseInt(activeTab, 10) + 1).toString());
  };
  const handleBusinessInfoSubmit = async (values) => {
    setLoading(true);
    const companyCategories = values?.category?.map(({ value }) => value) || [];
    const phone = companyID ? `+${values.countryCode}${values.phoneNumber}` : values?.phone?.phone;
    const payload = {
      name: values.orgName,
      organization_number: values.orgNumber?.value ? values.orgNumber.value : values.orgNumber,
      email: values.email,
      phone,
      websiteUrl: values.website,
      companyCategories,
      address: values.address,
      addressLink: values.addressLink,
      country: values.country?.name,
      zip: values.zip,
      city: values.city,
      companyStrenghts: values.description,
      companySupportDescription: values.descriptionSupportingClub,
      show: values.show,
      isSponsor: values.isSponsor,
      isAppVisible: values.isAppVisible,
      generalName: values.orgName,
    };
    if (values.companyLevel?.value) {
      payload.companyLevel = values.companyLevel?.value === 'None' ? null : values.companyLevel?.value;
    }
    try {
      if (companyID) {
        payload.companyLevel = values.companyLevel?.value === 'None' ? null : values.companyLevel?.value;
        await Api.companies.editCompany(payload, values.id);
        setLoading(false);
        toast.success(`${p('companyUpdated')}!`);
      } else if (selectedCompany) {
        await Api.companies.linkCompany({
          companyId: selectedCompany,
          companyLevel: values.companyLevel?.value,
          isSponsor: values.isSponsor,
          companySupportDescription: values.descriptionSupportingClub,
          zip: values.zip,
          city: values.city,
          country: values.country?.name,
          address: values.address,
          addressLink: values.addressLink,
          phone,
          email: values.email,
          name: values.orgName,
        });
        setLoading(false);
        toast.success(`${p('companyLinked')}!`);
      } else {
        const res = await Api.companies.createCompany(payload);
        const createdCompany = await Api.companies.getCompany(res.id);
        setCompany(createdCompany);
        setCreatedCompanyId(res.id);
        setLoading(false);
        toast.success(`${p('companyCreated')}!`);
        setShowSuccessModal(true);
        nextPage();
      }
    } catch (err) {
      setLoading(false);
      setShowErrorModal(true);
      toast.error(`${p(err.data?.message)}` || `${p('companySavingERROR')}`);
    }
  };

  const handleNewEmpolyeeSubmit = async (values) => {
    const phone = `+${values.countryCode}${values.phoneNumber}`;
    if (!Object.keys(company).length > 0) {
      toast.error(`${p('noBusinessInfo')}!`);
      return;
    }
    setLoading(true);
    try {
      const company_id = company.id;
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone,
        positionTitle: values.companyPosition,
        userId: values.userId,
        clubShortName: config.club_name_short,
        clubName: config.club_name,
        clubLogo: config.club_logo,
        lang,
        visibleCompany: values.visibleCompany,
        visiblePrivate: values.visiblePrivate,
        sendMessage: true,
      };
      await Api.employees.addEmployee(payload, company_id);
      toast.success(`${p('userAddedtoCompany')}!`);
      setLoading(false);
      if (company_id) {
        getEmployees();
      } else {
        history.replace('/companies');
      }
    } catch (err) {
      setLoading(false);
      toast.error(`${p(err.data.message)}` || `${p('addEmployeeError')}`);
    }
  };

  const handleDeleteButton = async (deleteIndex, textArea) => {
    const payload = {
      deleteComment: textArea,
    };
    setLoading(true);
    try {
      await Api.employees.removeEmployee(employees[deleteIndex].id, payload);
      setLoading(false);
      getEmployees();
    } catch (err) {
      toast.error(err || `${p('companyDeletingERROR')}`);
      getCompanyInformation();
      setLoading(false);
    }
  };
  const uploadRemoveImage = async (values, fieldName, companyId) => {
    const upload = (await values[fieldName]?.promise?.('company', fieldName, companyId) || values[fieldName]?.preview?.includes('blob'));
    if (!upload && !values[fieldName]?.preview) {
     await values[fieldName]?.remove?.('company', fieldName, companyId, values[fieldName]?.hash);
    }
   };
  const handleSponsorSubmit = async (values) => {
    const companyId = companyID || createdCompanyId;
    setLoading(true);
    try {
      uploadRemoveImage(values, 'companyImage', companyId);
      uploadRemoveImage(values, 'appSponsorImage', companyId);
      uploadRemoveImage(values, 'coverImage', companyId);
      uploadRemoveImage(values, 'appTicketImage', companyId);
      uploadRemoveImage(values, 'printImage', companyId);
      uploadRemoveImage(values, 'playerImage', companyId);
      toast.success(`${p('sponsorUpdated')}`);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('sponsorUpdatingERROR')}`);
      setLoading(false);
    }
  };

  const onCompanySelect = (id) => {
    setselectedCompany(id);
    setExistingCompanyFound(true);
  };
  return (
    <Container>
      <Loading loading={loading} />
      <AlertModal
        visible={showConfirmationModal}
        handleModal={setShowConfirmationModal}
        modalAction={() => history.replace('/companies')}
        modalType={AlertModalType.CONFIRMATION}
        message={a('sureCancel')}
      />
      <AlertModal
        visible={showSuccesModal}
        handleModal={setShowSuccessModal}
        modalType={AlertModalType.SUCCESS}
        subText={s('companySuccessMsg')}
        message={a('companyCreated')}
      />
      <AlertModal
        visible={showErrorModal}
        handleModal={setShowErrorModal}
        modalType={AlertModalType.ERROR}
        message={s('somethingWentWrong')}
      />
      {/* <LinkModal visible={linkModal} handleModal={setLinkModal} modalAction={linkCompany} /> */}

      {!companyID ? (
        <Card>
          <CardBody style={{ padding: 0 }}>
            <NavTab
              tabs={[
                { tab: getPageHeading(1) },
                { tab: getPageHeading(2), disabled: !createdCompanyId },
                { tab: p('sponsorMaterial'), disabled: !createdCompanyId },
                { tab: t('EventsPage.eventCategories'), disabled: !createdCompanyId },
              ]}
              setTab={setActiveTab}
              activeTab={activeTab}
              size="sm"
            />
            <div className="tabs tabs--justify tabs--bordered-top overflowY-hidden">
              <div className="tabs__wrap m-0 p-0">
                <TabContent activeTab={activeTab}>
                  <TabPane style={{ padding: 5 }} tabId="1">
                    <BusinessInfoForm
                      company={company}
                      categories={categories}
                      levels={levels}
                      onSubmit={handleBusinessInfoSubmit}
                      type="create"
                      companyOptions={companyOptions}
                      getCompanies={getCompanies('create')}
                      handleExistingCompanySelect={handleExistingCompanySelect}
                      existingCompanyFound={existingCompanyFound}
                      onCompanySelect={onCompanySelect}
                      onCancel={() => setShowConfirmationModal(true)}
                    />
                  </TabPane>

                  <TabPane style={{ padding: 5 }} tabId="2">
                    <AddEmployee
                      employees={employees}
                      refecthEmployees={getEmployees}
                      handleDeleteButton={handleDeleteButton}
                      handleNewEmpolyeeSubmit={handleNewEmpolyeeSubmit}
                      type="create"
                      nextPage={nextPage}
                    />
                  </TabPane>
                  <TabPane style={{ padding: 5 }} tabId="3">
                    <SponsorForm company={company} onSubmit={handleSponsorSubmit} />
                  </TabPane>
                  <TabPane style={{ padding: 5 }} tabId="4">
                    <CustomEventCategoryCard companyID={companyID} setLoading={setLoading} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody className="p-0">
            <NavTab
              tabs={[
                { tab: s('company') },
                { tab: s('employees'), disabled: !companyID },
                { tab: p('sponsorMaterial'), disabled: !companyID },
                { tab: t('EventsPage.eventCategories'), disabled: !companyID },
              ]}
              setTab={setActiveTab}
              activeTab={activeTab}
              size="sm"
            />
            <div className="tabs tabs--justify tabs--bordered-top overflow-unset p-0">
              <div className="tabs__wrap">
                <TabContent activeTab={activeTab}>
                  <TabPane style={{ padding: 5 }} tabId="1">
                    <BusinessInfoForm
                      getCompanies={getCompanies('edit')}
                      categories={categories}
                      company={company}
                      levels={levels}
                      onSubmit={handleBusinessInfoSubmit}
                      type="edit"
                      existingCompanyFound={existingCompanyFound}
                    />
                  </TabPane>
                  <TabPane style={{ padding: 5 }} tabId="2">
                    <AddEmployee
                      employees={employees}
                      refecthEmployees={getEmployees}
                      handleDeleteButton={handleDeleteButton}
                      handleNewEmpolyeeSubmit={handleNewEmpolyeeSubmit}
                      type="edit"
                    />
                  </TabPane>
                  <TabPane style={{ padding: 5 }} tabId="3">
                    <SponsorForm company={company} onSubmit={handleSponsorSubmit} />
                  </TabPane>
                  <TabPane style={{ padding: 5 }} tabId="4">
                    <CustomEventCategoryCard companyID={companyID} setLoading={setLoading} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default CompanyFormPage;
