import { Route } from 'react-router-dom';
import React from 'react';
import Layout from '../_layout';
import Pages from './Pages';

const WrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/profile" component={Pages} />
      <Route path="/vendors" component={Pages} />
      <Route path="/clubadmin" component={Pages} />
      <Route path="/seasons" component={Pages} />
      <Route path="/matches" component={Pages} />
      <Route path="/sponsor_news" component={Pages} />
      <Route path="/news" component={Pages} />
      <Route path="/news_api" component={Pages} />
      <Route path="/news_providers" component={Pages} />
      <Route path="/news_category" component={Pages} />
      <Route path="/users" component={Pages} />
      <Route path="/companies" component={Pages} />
      <Route path="/deals" component={Pages} />
      <Route path="/events" component={Pages} />
      <Route path="/offers" component={Pages} />
      <Route path="/tickets" component={Pages} />
      <Route path="/discounts" component={Pages} />
      <Route path="/cnc" component={Pages} />
      <Route path="/messages" component={Pages} />
      <Route path="/messages_api" component={Pages} />
      <Route path="/notification_label" component={Pages} />
      <Route path="/settings" component={Pages} />
      <Route path="/stadiums" component={Pages} />
      <Route path="/reports" component={Pages} />
      <Route path="/balances" component={Pages} />
      <Route path="/packages" component={Pages} />
      <Route path="/players" component={Pages} />
      <Route path="/auctions" component={Pages} />
      <Route path="/questionnaires" component={Pages} />
      <Route path="/prizes" component={Pages} />
      <Route path="/external_stores" component={Pages} />
      <Route path="/spotlight" component={Pages} />
      <Route path="/lottery" component={Pages} />
      <Route path="/app_layout" component={Pages} />
      <Route path="/user_tiers" component={Pages} />
      <Route path="/contact-sales" component={Pages} />
      {/* <Route path="/ad_widget" component={Pages} /> */}
      <Route exact path="/" component={Pages} />
    </div>
  </div>
);
export default WrappedRoutes;
