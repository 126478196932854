import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Container, Button } from 'reactstrap';
import { useHistory } from 'react-router';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import renderField from '../../../shared/components/custom/Field';
import FormBox from '../../../shared/components/custom/FormBox';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import validatePrizeForm from './validatePrizeForm';
import { partial } from '../../../util/translation/translation';
import { getImageHash } from '../../../util/functions';

const PrizeForm = ({
    prize, handleSubmit, initialize, prizeId,
}) => {
    const history = useHistory();
  const s = partial('shared');
  useEffect(() => {
    if (prize) {
      initialize({
        title: prize.title,
        description: prize.description,
        headingImage: {
          name: 'prize image',
          preview: prize.imageUrl,
          croppedImage: prize.imageUrl,
          hash: getImageHash(prize.imageUrl),
        },
      });
    }
  }, [prize]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col md={6} className="mb-2">
            <FormBox title={s('details')}>
              <Col md={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('title')}*</span>
                  <Field
                    name="title"
                    component={renderField}
                    placeholder={s('title')}
                    emoji
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('description')}*</span>
                  <Field
                    name="description"
                    component={renderTextAreaField}
                    placeholder={s('description')}
                    maxLength={500}
                    emoji
                  />
                </div>
              </Col>
            </FormBox>
          </Col>
          <Col md={6}>
            <FormBox title={s('image')}>
              <div className="form__form-group">
                <Field
                  saveBase64Cropped
                  name="headingImage"
                  maxSize={3}
                  component={renderDropZoneField}
                  displayCrop
                  crop="BANNER_CROP"
                />
                <span className="form-header"><strong>{s('note')}:</strong> {s('recommendedImageSize')} 16:9 (1035 x 570 px)</span>
              </div>
            </FormBox>
          </Col>
        </Row>
      </Container>
      <Col md={12}>
        <Button color="primary" type="submit">
          {prizeId ? s('save') : s('create')}
        </Button>
        <Button type="button" onClick={() => history.push('/matches/upcoming?tab=2')}>
          {s('close')}
        </Button>
      </Col>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'prize_form',
  validate: validatePrizeForm,
});

export default reduxF(PrizeForm);
