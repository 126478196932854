import React, { useEffect, useState } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Card, Col, Row, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import Api from '../../util/api';
import t, { partial } from '../../util/translation/translation';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';

const NotificationLabelPage = () => {
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [handleModal, visible, deleteID] = useModal();
  const s = partial('shared');


  const getApiAccess = async () => {
    setLoading(true);
    try {
      const labelResponse = await Api.userDevices.getAllNotificationLabels();
      setLabels(labelResponse);
      setLoading(false);
    } catch (err) {
        setLoading(false);
      toast.error(t('api.404'));
    }
  };
  useEffect(() => {
    getApiAccess();
  }, []);
  const renderApiKeys = () => {
    return labels.map((label) => {
      return (
        <tr key={`apiAccess-${label.id}`} className="center-list">
          <td>
            <Link to={{ pathname: `/notification_label/edit/${label.id}` }}>
              {label.title}
            </Link>
          </td>
          <td>{label.approvalsCount}</td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/notification_label/edit/${label.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem
                  onClick={() => handleModal(label.id)}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };
  const deleteNotificationLabel = async () => {
    setLoading(true);
    try {
      await Api.userDevices.deleteNotificationLabel(deleteID);
      toast.success(s('deleteSuccessful'));
    } catch (err) {
        toast.error(t('api.404'));
    } finally {
      handleModal();
      getApiAccess();
    }
  };
  return (
    <Container className="form d-flex">

      <Loading loading={loading} />
      <DeleteModal
        visible={visible}
        type={t('MessagesPage.notificationLabel')}
        handleModal={handleModal}
        modalAction={deleteNotificationLabel}
      />
      <Card>
        <Col>
          <Row>
            <h3 className="page-title mb-0">{t('MessagesPage.notificationLabel')}</h3>
            <Link to={{ pathname: '/notification_label/create' }} style={{ marginLeft: 'auto' }}>
              <Button color="primary" size="sm">
                {s('create')} {t('MessagesPage.notificationLabel')}
              </Button>
            </Link>
          </Row>
        </Col>
        <div className="mt-4" style={{ display: 'flex', paddingBottom: 5, marginTop: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th >
                  <div className="flex alignItems-center">{s('title')}</div>
                </th>
                <th >
                  <div className="flex alignItems-center">{t('MessagesPage.approvalsCount')}</div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderApiKeys()}
            </tbody>
          </Table>
        </div>
      </Card>

    </Container>
  );
};

export default NotificationLabelPage;
