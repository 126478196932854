import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col,
  ButtonToolbar,
  Table,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIcon from 'mdi-react/DragIcon';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import useConfig from '../../util/useConfig';

const s = partial('shared');
const p = partial('UserTiers');
const UserTiers = () => {
  const config = useConfig();
  const [userTiers, setUserTiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    modalVisible: false,
    deleteId: null,
    deleteType: '',
  });
  const getAllTiers = async (onEnd = () => { }) => {
    setLoading(true);
    try {
      let { data } = await Api.tiers.allTiersWithDefault(config);
      const removeTiers = ['Default tier', 'Guest user'];
      data = data.filter((item) => !removeTiers.includes(item.name));
      const tiersWithOrder = data.map((item, idx) => ({ ...item, order: idx + 1 }));
      setUserTiers(tiersWithOrder);
      setLoading(false);
    } catch (err) {
      toast.error(err?.data?.message);
      setLoading(false);
    }
    onEnd();
  };
  useEffect(() => {
    getAllTiers();
  }, []);
  const handleModal = (state, id, newsType) => {
    setModalData({ modalVisible: state, deleteId: id, deleteType: newsType });
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      const deleteTierId = modalData.deleteId;
      await Api.tiers.deleteTier(deleteTierId, config);
      getAllTiers(() => {
        handleModal(false, null, 'tier');
        toast.success(p('tierDeleted'));
      });
    } catch (err) {
      handleModal(false, null, 'tier');
      toast.error(err);
      setLoading(false);
    }
  };
  const RenderDraggableTier = ({ tier, index }) => {
    return (
      <Draggable
        key={`group-${tier.id}`}
        draggableId={`group-${tier.id}`}
        index={index}
        isDragDisabled={false}
      >
        {(provided, snapshot) => (<RenderTierRow tier={tier} provided={provided} snapshot={snapshot} />)}
      </Draggable>
    );
  };
  const RenderTierRow = ({ tier, provided, snapshot }) => {
    return (
      <tr
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
        className={snapshot?.isDragging ? 'rbdnd-dragging' : 'center-list'}
        key={`tier-${tier.id}`}
      >

        {snapshot ? (
          <td className="flex-Vcenter">
            <DragIcon color="#999999" />
          </td>
        ) : <td />}
        {snapshot ? (
          <td >
            {tier.order}
          </td>
        ) : <td />}
        <td>
          <Link to={`/user_tiers/edit/${tier.id}`} className="flex-Vcenter">
            <img className="teamLogoTable" src={tier?.originalUrl} alt="logo" />
            {tier.name}
          </Link>
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/user_tiers/edit/${tier.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              {tier.canDelete &&
                <DropdownItem
                  onClick={() => handleModal(true, tier.id, 'tier')}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    );
  };
  const renderTierList = () => {
    return userTiers.map((tier, index) => {
      const isDraggable = tier.loginStage == null;
      return (
        isDraggable ? <RenderDraggableTier tier={tier} index={index} key={tier.id} /> : <RenderTierRow tier={tier} key={tier.id} />
      );
    });
  };

  const groupOnDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    setLoading(true);
    const draggedGroup = userTiers[source.index];
    const newTiers = [...userTiers];
    newTiers.splice(source.index, 1);
    newTiers.splice(destination.index, 0, draggedGroup);
    setUserTiers(newTiers);
    const orderedIds = newTiers.map((tier) => tier.id);
    try {
      await Api.tiers.updateTierOrder({ orderedIds }, config);
      getAllTiers();
    } catch (err) {
      setLoading(false);
      toast.error(p('updatingOrderFailed'));
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <p className="page-title">
            {p('tierList')}
          </p>
        </Col>
        <Col>
          <div className="card__title marginTop-0 float-right">
            <ButtonToolbar className="margin-top-0">
              <Link className="btn btn-primary btn-sm" to="/user_tiers/create">
                {p('createTier')}
              </Link>
            </ButtonToolbar>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
            <DragDropContext onDragEnd={groupOnDragEnd}>
              <Table responsive striped >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{s('priority')}</th>
                    <th className="sortable">
                      <div className="flex alignItems-center">{s('name')}</div>
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <Droppable droppableId="group-dnd">
                  {(provided) => {
                    return (
                      <>
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {renderTierList()}
                          {provided.placeholder}
                        </tbody>
                      </>
                    );
                  }}
                </Droppable>
              </Table>
            </DragDropContext>
          </div>
        </Col>
      </Row>
      <DeleteModal
        type={p('tier')}
        visible={modalData.modalVisible}
        handleModal={handleModal}
        modalAction={handleDelete}
      />
    </Container>
  );
};

export default UserTiers;
